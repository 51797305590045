import Vue from 'vue'
import Vuex from 'vuex'
// import * as fb from '../firebaseConfig'
const fb = require('../firebaseConfig.js')
import router from '../router/index'
import firebase from 'firebase/app';

Vue.use(Vuex)

fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setCurrentUser', user)
    store.dispatch('fetchUserProfile', user)
  }
})

const store = new Vuex.Store({
  state: {
    userProfile: {},
    currentUser: null,
    emailVerified: '',
    errorMessage: '',
    venues: [],
    featuredVenues: [],
    venueInfo: {},
    venueEvents: [],
    users:[],
    userInfo:{},
    workerPayHistory: {},
    employeePayHistory: {},
    events: [],
    currentEvents: [],
    recentEvents: [],
    eventInfo: {},
    venueEvent: {},
    venueJobs: [],
    userEventStatus: null,
    eventShifts: [],
    jobs:[],
    jobInfo: {},
    clients:[],
    clientInfo: {},
    followedVenues: [],
    preferredEvents: [],
    latestEvents: [],
    userAvailableDays: [],
    userAvailableData: [],
    appliedJobs: [],
    faqs: [],
    payFAQ: {},
    activeAssignments:[],
    upcomingAssignments: [],
    nYUsers: [],
    myAssignments: [],
    myAssignment: {},
    assignmentProximity: '',
    userReferrals: [],
    usersTotal: {},
    venuesTotal: {},
    checkInMaster: null,
    canCheckIn: false,
    verifications: [],
    infiniteEvents: [],
    nextInfiniteEvents: [],
    lastVisibleEventSnapShot: {},
    taggedEvents: [],
    venueEventsSearchResults: [],
    tags: [],
    venueCheckInEvent: {},
    dayAssignment: {},
    eventUsers: [],
    assignment: {},
    assignmentUser: {},
    groups: [],
    group: {},
    groupApplications: [],
    availableShifts: [],
    availableShiftsWithoutGroups: [],
    allAvailableShifts: [],
    myShifts: [],
    shiftInfo: {},
    activeShiftAssignments: [],
    groupShifts: [],
    skillCategories: [],
    jobsByCategory: [],
    userTimesheets: [],
    certs: [],
    panels: [],
    pipeline: [],
    pipelineCancelled: [],
    pipelineHired: []
    // allAvailableShifts: []
  },
  actions: {
    async login({ dispatch, commit, state }, form) {
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
      .catch(err => {
        console.log(err)
        commit('setErrorMessage', err.message)
        })

      // fetch user profile and set in state
      console.log(user)
      store.dispatch('fetchUserProfileOnLogin', user)
    },
    // async signupBusiness({ dispatch }, form) {
    //   const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

    //   await fb.businessUsersCollection.doc(user.uid).set({
    //     firstName: form.firstName,
    //     lastName: form.lastName,
    //     company: form.company,
    //     phone: form.phone,
    //     id: user.uid,
    //     email: form.email,
    //     created: fb.firestore.FieldValue.serverTimestamp(),
    //   })

    //   // fetch user profile and set in state
    //   fb.auth.currentUser.sendEmailVerification()
    //   store.dispatch('fetchBusinessUserProfile', user)

    // },

    async signupFormGroup({ dispatch }, form) {

      const stamp = Math.floor(Date.now() /1000);

      console.log(form)
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

      let applications = []
      applications.push(form.group)

      // interview: this.form
      // group: this.form.group


      // create user object in userCollections
      await fb.usersCollection.doc(user.uid).set({
        firstName: form.firstName,
        lastName: form.lastName,
        phone: form.phone,
        id: user.uid,
        email: form.email,
        dob: form.dob || null,
        address: form.address || null,
        center: form.center || null,
        shiftPreference: form.shiftPreference || null,
        skills: [],
        pics: [],
        employeeStatus: 'applied',
        contractorStatus: 'applied',
        interview: form.interview,
        interviewed: fb.firestore.FieldValue.serverTimestamp(),
        created: fb.firestore.FieldValue.serverTimestamp(),
        groupApplications: applications,
        logins: firebase.firestore.FieldValue.arrayUnion(stamp)
      })

      await fb.groupsCollection.doc(form.group).update({
        applicants: firebase.firestore.FieldValue.arrayUnion(user.uid)
      })

      await fb.loginsCollection.add({
        userId: user.uid,
        created: fb.firestore.FieldValue.serverTimestamp()
      })

      await fb.auth.currentUser.sendEmailVerification()
      store.dispatch('fetchUserProfileWithApplication', {
        user: user,
        form: form
      })
      // store.dispatch('onboardEveree', user)
      // store.dispatch('deleteLead', user)
      // router.push('/auth/verify')
      router.push('/auth/complete')
      
      
    },

    async signupFormGroupNY({ dispatch }, form) {

      const stamp = Math.floor(Date.now() /1000);

      console.log(form)
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

      let applications = []
      applications.push(form.group)

      // interview: this.form
      // group: this.form.group


      // create user object in userCollections
      await fb.usersCollection.doc(user.uid).set({
        firstName: form.firstName,
        lastName: form.lastName,
        phone: form.phone,
        id: user.uid,
        email: form.email,
        dob: form.dob || null,
        address: form.address || null,
        center: form.center || null,
        shiftPreference: form.shiftPreference || null,
        skills: [],
        pics: [],
        employeeStatus: 'applied',
        contractorStatus: 'applied',
        interview: form.interview,
        interviewed: fb.firestore.FieldValue.serverTimestamp(),
        created: fb.firestore.FieldValue.serverTimestamp(),
        groupApplications: applications,
        logins: firebase.firestore.FieldValue.arrayUnion(stamp),
        // isNY: true,
        locationPreference: form.location,
        // conditionalOffer: true
      })

      await fb.groupsCollection.doc(form.group).update({
        applicants: firebase.firestore.FieldValue.arrayUnion(user.uid)
      })

      await fb.loginsCollection.add({
        userId: user.uid,
        created: fb.firestore.FieldValue.serverTimestamp()
      })

      await fb.auth.currentUser.sendEmailVerification()
      store.dispatch('fetchUserProfileWithApplication', {
        user: user,
        form: form
      })

      const conditionalOffer = firebase.functions().httpsCallable('conditionalOffer', )
      await conditionalOffer({
        firstName: form.firstName,
        email: form.email,
        location: form.location
      })

      const orderCheck = firebase.functions().httpsCallable('orderCheck', )
      await orderCheck({
        userId: user.uid,
        firstName: form.firstName,
        lastName: form.lastName,
        phone: form.phone,
        email: form.email,
        packageid: '11409'
      })
      .then(result => {
        console.log(result)
        if (result && result.data) {
          console.log(result.data)
          return fb.usersCollection.doc(user.uid).update({
            checkInvitations: firebase.firestore.FieldValue.arrayUnion(result.data)
            // checkInvitation: result.data
          })
        }
      })


      // store.dispatch('onboardEveree', user)
      // store.dispatch('deleteLead', user)
      // router.push('/auth/verify')
      router.push('/auth/ny')
      
      
    },
    
    async signupForm({ dispatch }, form) {

      const stamp = Math.floor(Date.now() /1000);

      console.log(form)
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

      // create user object in userCollections
      await fb.usersCollection.doc(user.uid).set({
        firstName: form.firstName,
        lastName: form.lastName,
        phone: form.phone,
        id: user.uid,
        email: form.email,
        dob: form.dob || null,
        address: form.address || null,
        center: form.center || null,
        skills: [],
        pics: [],
        employeeStatus: 'applied',
        contractorStatus: 'applied',
        interview: form.interview,
        interviewed: fb.firestore.FieldValue.serverTimestamp(),
        created: fb.firestore.FieldValue.serverTimestamp(),
        logins: firebase.firestore.FieldValue.arrayUnion(stamp)
      })

      await fb.loginsCollection.add({
        userId: user.uid,
        created: fb.firestore.FieldValue.serverTimestamp()
      })

      await fb.auth.currentUser.sendEmailVerification()
      store.dispatch('fetchUserProfileWithApplication', {
        user: user,
        form: form
      })
      // store.dispatch('onboardEveree', user)
      // store.dispatch('deleteLead', user)
      // router.push('/auth/verify')
      router.push('/auth/complete')
      
    },
    async signupUser({ dispatch }, form) {

      console.log(form)
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

      const stamp = Math.floor(Date.now() /1000);

      // create user object in userCollections
      await fb.usersCollection.doc(user.uid).set({
        firstName: form.firstName,
        lastName: form.lastName,
        howhear: form.howhear,
        phone: form.phone,
        id: user.uid,
        email: form.email,
        dob: form.dob,
        skills: [],
        interview: {},
        pics: [],
        employeeStatus: 'applied',
        contractorStatus: 'applied',
        logins: firebase.firestore.FieldValue.arrayUnion(stamp)
      })

      await fb.loginsCollection.add({
        userId: user.uid,
        created: fb.firestore.FieldValue.serverTimestamp()
      })

      await fb.auth.currentUser.sendEmailVerification()
      store.dispatch('fetchUserProfile', user)
      // store.dispatch('onboardEveree', user)
      // router.push('/auth/verify')
      router.push('/auth/complete')
      
    },
    async fetchBusinessUserProfile({ commit }, user) {
      console.log("fetching business profile")
      const userProfile = await fb.usersCollection.doc(user.uid).get()
      commit('setUserProfile', userProfile.data())
    },
    async fetchUserProfileOnLogin({ commit, state }, user) {
      console.log("fetching")
      console.log(state.currentUser.uid)
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(state.currentUser.uid).get()
      .then(doc => {

        if (doc.exists) {

          // set user profile in state
          commit('setUserProfile', doc.data())

          console.log('exists')
          // if (!doc.data().pics || doc.data().pics.length == 0) {
          //   fb.usersCollection.doc(doc.data().id).update({
          //     pics: []
          //   })
          // }
          
            // set user profile in state
          commit('emailVerified', state.currentUser.emailVerified)

          const stamp = Math.floor(Date.now() /1000);

          fb.loginsCollection.add({
            userId: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp()
          })

          fb.usersCollection.doc(doc.id).update({
            logins: firebase.firestore.FieldValue.arrayUnion(stamp)
          })

          

          // // store.dispatch('getUserAvailability')
          // store.dispatch("getGroups")
          // // // store.dispatch("getAvailableShifts")
          // store.dispatch("getAvailableShiftsWithoutGroups")
          // // store.dispatch("getMyShifts")

        } else {
          console.log('not exists')
          // this.logout()
        }

      })
      if (state.userProfile && state.userProfile.email) {
        
        router.push('/jobs/apply')
      }
      store.dispatch('getUserAvailability')
      store.dispatch('getUserJobAvailability')
      
    },
    async fetchUserProfile({ commit, state }, user) {
      console.log("fetching")
      console.log(state.currentUser.uid)
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(state.currentUser.uid).get()
      .then(doc => {

        if (doc.exists) {

          // set user profile in state
          commit('setUserProfile', doc.data())

          console.log('exists')
          // if (!doc.data().pics || doc.data().pics.length == 0) {
          //   fb.usersCollection.doc(doc.data().id).update({
          //     pics: []
          //   })
          // }
          
            // set user profile in state
          commit('emailVerified', state.currentUser.emailVerified)

          

          
          // store.dispatch("getGroups")
          // // store.dispatch("getAvailableShifts")
          // store.dispatch("getAvailableShiftsWithoutGroups")
          // // store.dispatch("getMyShifts")

        } else {
          console.log('not exists')
          this.logout()
        }

      })
      store.dispatch('getUserAvailability')
      store.dispatch('getUserJobAvailability')
    },
    // async fetchEverythingElse({ commit }) {
    //   store.dispatch('getUserAvailability')
    //   // store.dispatch("getGroups")
    //   // store.dispatch("getAvailableShiftsWithoutGroups")
    //   store.dispatch("getMyShifts")
    // },

    async fetchUserProfileWithApplication({ commit, state }, payload) {
      console.log("fetching with application")
      console.log(payload)
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(state.currentUser.uid).get()
      .then(doc => {

        

        if (doc.exists) {
        console.log('exists')
        commit('setUserProfile', doc.data())
          // if (!doc.data().pics || doc.data().pics.length == 0) {
          //   fb.usersCollection.doc(doc.data().id).update({
          //     pics: []
          //   })
          // }
          
            // set user profile in state
          // commit('emailVerified', state.currentUser.emailVerified)

          // set user profile in state
          // commit('setUserProfile', doc.data())

          // store.dispatch('addAvailablity', {
          //   user: doc.data(),
          //   day: payload.form.day,
          //   event: payload.form.event,
          //   shift: payload.form.shift
          // })

          // store.dispatch('getUserAvailability')
          // store.dispatch("getGroups")
          // // store.dispatch("getAvailableShifts")
          // store.dispatch("getAvailableShiftsWithoutGroups")
          // store.dispatch("getMyShifts")

        } else {
          console.log('not exists')
        }

      })
      store.dispatch('getUserAvailability')
      store.dispatch('getUserJobAvailability')
    },
    async logout({ commit }) {
      // log user out
      await fb.auth.signOut()

      // clear user data from state
      commit('setUserProfile', {})
      commit('setUserReferrals', [])
      commit('setUserTimesheets', [])
      commit('setCurrentUser', null)
      commit('setFollowedVenues', [])
      commit('setEvents', [])
      commit('setCurrentEvents', [])
      commit('setVenues', [])
      commit('setRecentEvents', [])
      commit('setAvailableData', [])
      commit('setAppliedJobs', [])
      commit('setAvailableDays', [])
      commit('setPreferredEvents', [])
      commit('setInfiniteEvents', [])
      commit('setMyShifts', [])
      commit('setAvailableShifts', [])
      commit('setAvailableShiftsWithoutGroups', [])


      // redirect to login view
      router.push('/')
    },
    getUserProfile({ commit, state }) {
      console.log('getting profile');

      const userDoc = fb.usersCollection.doc(state.currentUser.uid);

      userDoc.onSnapshot(
        (doc) => {
          if (doc.exists) {
            commit('setUserProfile', doc.data());
          } else {
            // Handle the case where the document doesn't exist
          }
        },
        (error) => {
          console.error(error);
          // Handle any errors that may occur during the snapshot listener
        }
      );
    },

    // getUserProfile({ commit, state }) {
    //   console.log('getting profile')
    //   fb.usersCollection.doc(state.currentUser.uid)
    //   .onSnapshot(function (doc) {
    //     if (doc.exists) {
    //       commit('setUserProfile', doc.data())
    //     } else {

    //     }
    //   })
    // },
    // getFollowingState({ commit, state }) {
    //   fb.venueFollowersCollection.where("user", "==", state.currentUser.uid).onSnapshot(querySnapshot => {
    //     let followingArray = []
    //     querySnapshot.forEach(doc => {
    //       let followed = doc.data().venue
    //       followingArray.push(followed)
    //     })
    //     commit('setFollowedVenues', followingArray)
    //   })
    // },
    updateUserRequired({ commit }, payload) {
      payload.payrollSubmitted = true
      payload.payrollSubmittedStamp = fb.firestore.FieldValue.serverTimestamp()
      console.log(payload)
      fb.usersCollection.doc(payload.id).update(payload)
      // .then(
      //   fb.usersCollection.doc(payload.id).update({
          
      //   })
      // )
      // .then(doc => {
      //   fb.usersCollection.doc(doc.id).update({
          
      //   })
      // })
      // commit('setUserProfile', payload)
      fb.payrollSubmissionsCollection.add({
        userId: payload.id,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phone: payload.phone,
        dob: payload.dob,
        state: payload.address.state || null,
      })
      .then(
        doc => {
          console.log(doc.id)
          fb.payrollSubmissionsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    updateUserProfile({ commit }, payload) {
      fb.usersCollection.doc(payload.id).update(payload)
      const userProfile = fb.usersCollection.doc(payload.user.id).get()
      commit('setUserProfile', userProfile)
    },
    updateMyProfile({ commit }, payload) {
      fb.usersCollection.doc(payload.id).update(payload)
      // const userProfile = fb.usersCollection.doc(payload.id).get()
      // commit('setUserProfile', userProfile)
    },
    updateUser({ commit, state }, payload) {
      console.log(payload)
      let user = state.currentUser
      fb.usersCollection.doc(state.currentUser.uid).update(payload)
      .then(
        store.dispatch('getUserProfile')
      )
    },
    deleteUserHeadshot({ commit }, payload) {
      fb.usersCollection.doc(payload).update({
        headshot: firebase.firestore.FieldValue.delete()
      })
      .then(
        store.dispatch('getUserProfile')
      )
    },
    deleteUserBodyshot({ commit }, payload) {
      fb.usersCollection.doc(payload).update({
        bodyshot: firebase.firestore.FieldValue.delete()
      })
      .then(
        store.dispatch('getUserProfile')
      )
    },

    async updateUserNewVerifyRequest({ commit, state }) {
      await fb.usersCollection.doc(state.currentUser.uid).update({
        newVerifyRequest: fb.firestore.FieldValue.serverTimestamp()
      })
      var newRequest = firebase.functions().httpsCallable('newVerifyRequestGetCode');
      newRequest()
      .then((result) => {
        console.log(result)
      })
    },
    updateUserPhoneOnly({ commit, state }, payload) {
      console.log(payload)
      let user = state.currentUser
      let noPhone = {phone: ''}
      console.log(noPhone)
      fb.usersCollection.doc(state.currentUser.uid).update(noPhone)
      fb.usersCollection.doc(state.currentUser.uid).update(payload)
      .then(
        store.dispatch('getUserProfile')
      )
    },
    updateUserEmail({ commit }, payload) {
      const user = fb.auth.currentUser

      console.log(user)
      user.updateEmail(payload.email).then(() => {
       console.log('email changed')
      }).catch((error) => {
        console.log(error)
        commit('setErrorMessage', error)
      })
      fb.usersCollection.doc(payload.id).update(payload)
      .then(
        store.dispatch('getUserProfile')
      )
    },
    resendVerificationEmail({ commit }) {
      fb.auth.currentUser.sendEmailVerification()
      .then(() => {
       console.log('email changed')
      }).catch((error) => {
        console.log(error)
      })
    },


    clearDashState({ commit }, payload) {
      commit('setUpcomingAssignments', [])
    },




    async getAssignmentProximity({ commit, state }, payload) {
  console.log('getAssignemntProximity');
  console.log(payload);

  try {
    if (
      payload &&
      typeof payload.lat === 'number' &&
      typeof payload.lng === 'number'
    ) {
      console.log('valid coords');
      // Parse the payload distance as a number
      const distance = parseFloat(.25);
      console.log('distance', distance);

      // Check if the parsed distance is a valid number
      if ((!isNaN(distance)) && state && state.myAssignment && state.myAssignment.eventInfo && state.myAssignment.eventInfo.event_location) {
        const myLat = payload.lat;
        const myLng = payload.lng;

        

        //Get Assignment Coords
        let assLat = state.myAssignment.eventInfo.event_location._lat
        let assLng = state.myAssignment.eventInfo.event_location._long



        const earthRadiusMiles = 3958.8; // Radius of the Earth in miles

          if (assLat && assLat !== null) {
            console.log('assLat', assLat);
            console.log('assLng', assLng);
            // const assLat = userCenter.lat;
            // const assLng = userCenter.lng;
            const dLat = (assLat - myLat) * (Math.PI / 180);
            const dLng = (assLng - myLng) * (Math.PI / 180);
            const a =
              Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(myLat * (Math.PI / 180)) *
              Math.cos(assLat * (Math.PI / 180)) *
              Math.sin(dLng / 2) *
              Math.sin(dLng / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distanceInMiles = earthRadiusMiles * c;

            

            if (distanceInMiles <= 3000) {
              console.log("distanceInMiles", distanceInMiles.toFixed(2));
              commit('setAssignmentProximity', distanceInMiles.toFixed(2));
              
            }
          }

      } else {
        // Handle the case where 'payload.distance' is not a valid number
        console.error('Invalid distance:', payload.distance);
        // You can choose to set a default value or show an error message
      }
    } else {
      // Handle the case where payload or payload.coords is not as expected
      console.error('Invalid payload or payload.coords:', payload);
      // You can choose to set a default value or show an error message
    }
  } catch (error) {
    console.error('Error fetching users by proximity:', error);
    // Handle error as needed, e.g., show an error message
  }
},


    async getNYUsers({ commit }) {
  console.log('getNYUsers');
  
  try {
    const unsubscribe = await fb.usersCollection
      .where('isNY', '==', true)
      .orderBy('created', 'desc')
      .onSnapshot(querySnapshot => {
        console.log(querySnapshot.size);
        let usersArray = [];

        querySnapshot.forEach((doc) => {
          let user = doc.data();
          usersArray.push(user);
        });

        commit('setNYUsers', usersArray);
      });

    // Store the unsubscribe function for later use (e.g., when the component is destroyed)
    // commit('setNYUsersUnsubscribe', unsubscribe);
  } catch (error) {
    console.log('Error fetching NY users:', error);
    // Handle error as needed, e.g., show an error message
  }
},



         
      clearNYUsers({ commit }) {
        commit('setNYUsers', []);
      },



    /*Shift Lead Applications*/
    newLeadApplication({ commit }, payload) {
      fb.leadApplicationsCollection.add(payload)
      .then(
        doc => {
        fb.leadApplicationsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp(),
        })
        fb.usersCollection.doc(payload.user.id).update({
          leadApplicationSubmitted: true,
          leadApplication: payload.form,
          leadStatus: 'New',
          leadApplicationId: doc.id
        })
      })
      
    },


    /*CERTS*/
    getCerts({ commit }) {
      fb.certsCollection
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let certsArray = []
        querySnapshot.forEach(doc => {
          let cert = doc.data()
          certsArray.push(cert)
        })
        commit('setCerts', certsArray)
      })
    },
    clearCerts({ commit }) {
      commit('setCerts', [])
    },


    /*QUOTES*/
    newQuoteRequest({ commit }, payload) {
      fb.quoteRequestsCollection.add(payload)
      .then(
      doc => {
        fb.quoteRequestsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp(),
        })
      })
    },


    /*EVEREE*/

    onboardEmployee({ commit }, payload) {
      console.log('onboardEmployee')
      const userProfile = fb.usersCollection.doc(payload.uid).get()
      .then(doc => {
        let phone = doc.data().phone.replace(/[^0-9]/g, '');
        let firstName = doc.data().firstName
        let lastName = doc.data().lastName
        let email = doc.data().email
        let id = doc.data().id
        let line1 = doc.data().address.street_number + ' ' + doc.data().address.street
        let city = doc.data().address.city
        let state = doc.data().address.state
        let zip = doc.data().address.zip
        let line2 = (doc.data().address.unit || '')

        const onboardEveree = firebase.functions().httpsCallable('employeeEmbeddedOnboarding')
        onboardEveree({
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          email: email,
          id: id,
          line1: line1,
          city: city,
          state: state,
          zip: zip,
          line2: line2
        })
        .then(result => {
          console.log(result.data)
          fb.usersCollection.doc(payload.uid).update({
            workerId: result.data.workerId
          })
        })

      })
    },

    onboardEveree({ commit }, payload) {
      const userProfile = fb.usersCollection.doc(payload.uid).get()
      .then(doc => {
        console.log(doc.data())
        let phone = doc.data().phone.replace(/[^0-9]/g, '');
        let firstName = doc.data().firstName
        let lastName = doc.data().lastName
        let email = doc.data().email
        let externalWorkerId = doc.data().id

        const onboardEveree = firebase.functions().httpsCallable('kickOffContractor')
        onboardEveree({
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          email: email,
          id: externalWorkerId

        })
        .then(result => {
          console.log(result.data)
          fb.usersCollection.doc(payload.uid).update({
            workerId: result.data.workerId
          })
        })
      })
    },


    /*PAYROLL FORM*/
    pFormSubmit({ commit }, payload) {
      console.log(payload)
      fb.payHelpCollection.add(payload)
      .then(
        doc => {
          fb.payHelpCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },


    /*GROUPS*/
    // async getAvailableShiftsWithoutGroups({ commit, state }) {
    //   // console.log('getAvailableShiftsWithoutGroups')
    //   await fb.shiftsCollection.orderBy('day', 'asc').onSnapshot(querySnapshot => {
    //     let shiftsArray = []
    //     querySnapshot.forEach(doc => {
    //       let yesterday = new Date()
    //       yesterday.setDate(yesterday.getDate() - 1);
    //       let startComp = new Date(doc.data().day)
    //       if ((startComp.getTime() >= yesterday.getTime()) && (!doc.data().status || doc.data().status == 'Open') && (!doc.data().type || doc.data().type != 'Job')) {
    //         shiftsArray.push(doc.data())
    //       }
    //     })
    //     // console.log(shiftsArray)
    //     commit('setAvailableShiftsWithoutGroups', shiftsArray)
    //     // store.dispatch("getAllMyShifts")
    //   })
      
    // },

    // async getGroups({ commit }) {
    //   // console.log('getAvailableShiftsWithoutGroups')
    //   await fb.shiftsCollection.orderBy('day', 'asc')
    //     .get().then(querySnapshot => {
    //   // .onSnapshot(querySnapshot => {
    //     let groupsArray = []
    //     querySnapshot.forEach(doc => {
    //       let yesterday = new Date()
    //       yesterday.setDate(yesterday.getDate() - 1);
    //       let startComp = new Date(doc.data().day)
    //       if ((!doc.data().status || doc.data().status == 'Open') && (doc.data().type && doc.data().type == 'Job')) {
    //         groupsArray.push(doc.data())
    //       }
    //     })
    //     // console.log(groupsArray)
    //     commit('setGroups', groupsArray)
    //     // store.dispatch("getAllMyShifts")
    //   })
      
    // },
    getGroups({ commit }) {
      console.log('getting groups');
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      fb.shiftsCollection
        .where('eventStatus', '==', 'Open')
        .where('staffingStatus', '==', 'Open')
        .where('type', '==', 'Job')
        .orderBy('day', 'asc')
        .get()
        .then(querySnapshot => {
          const groupsArray = querySnapshot.docs.map(doc => doc.data());
          commit('setGroups', groupsArray);
        })
        .catch(error => {
          console.error(error);
          // Handle any errors that may occur during the process
        });
    },


    // async getGroups({ commit }) {
    //   // await fb.groupsCollection.where('visible', '==', true).orderBy('title', 'asc')
    //   await fb.eventsCollection.orderBy('startDate', 'desc').limit(200)
    //   .get()
    //   .then(snapshot => {
    //     let groupsArray = []
    //     snapshot.forEach(doc => {
    //       console.log(doc.id)
    //       let group = doc.data()
    //       if ((doc.data().shiftType && doc.data().shiftType == 'Job') && (!doc.data().status || doc.data().status == 'Open')) {
    //         groupsArray.push(group)
    //       }
    //     })
    //     commit('setGroups', groupsArray)
    //   })
    //   // store.dispatch("getAvailableShifts")
    // },
    async getGroupFromId({ commit }, payload) {
      await fb.groupsCollection.doc(payload).get()
      .then(
        doc => {
          commit("setGroup", doc.data())
        }
      )
      // store.dispatch('getGroupShifts', payload)
    },




    // async getGroupShifts({ commit }, payload) {
    //   await fb.shiftsCollection.where("groupIds", "array-contains", payload)
    //   .get().then(querySnapshot => {
    //   // .onSnapshot(querySnapshot => {
    //     let shiftsArray = []
    //     let yesterday = new Date()

    //     yesterday.setDate(yesterday.getDate() - 1);
    //     console.log(yesterday)
    //     querySnapshot.forEach(doc => {
    //       if (doc.exists) {
    //         let startComp = new Date(doc.data().day)
    //         if (startComp >= yesterday) {
    //           shiftsArray.push(doc.data())
    //         } else {
    //           console.log('earlier')
    //         }
    //       } else {
    //         console.log('no docs')
    //       }
    //     })
    //     console.log(shiftsArray)
    //     commit('setGroupShifts', shiftsArray)
    //   })
    // },
    async getGroupShifts({ commit }, payload) {
      try {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        
        const querySnapshot = await fb.shiftsCollection
          .where("groupIds", "array-contains", payload)
          .where("day", ">=", yesterday)
          .get();
        
        const shiftsArray = [];

        querySnapshot.forEach(doc => {
          if (doc.exists) {
            shiftsArray.push(doc.data());
          }
        });

        commit('setGroupShifts', shiftsArray);
      } catch (error) {
        console.error(error);
        // Handle any errors that may occur during the process
      }
    },

    applyToGroup({ commit }, payload) {
      console.log(payload)
      fb.usersCollection.doc(payload.user.id).update({
        groupApplications: firebase.firestore.FieldValue.arrayUnion(payload.group.groupId),
      })
      fb.groupsCollection.doc(payload.group.groupId).update({
        applicants: firebase.firestore.FieldValue.arrayUnion(payload.user.id)
      })
      store.dispatch('getUserProfile')
    },
    leaveGroup({ commit }, payload) {
      console.log(payload)
      console.log(payload.user.id)
      fb.usersCollection.doc(payload.user.id).update({

        groups: firebase.firestore.FieldValue.arrayRemove(payload.group.groupId),
      })
      fb.groupsCollection.doc(payload.group.groupId).update({
        members: firebase.firestore.FieldValue.arrayRemove(payload.user.id)
      })
      store.dispatch('getUserProfile')
    },
    cancelWaitApplicationToGroup({ commit }, payload) {
      fb.usersCollection.doc(payload.user.id).update({
        groupWaitlist: firebase.firestore.FieldValue.arrayRemove(payload.group.groupId),
      })
      fb.groupsCollection.doc(payload.group.groupId).update({
        waitlist: firebase.firestore.FieldValue.arrayRemove(payload.user.id)
      })
      store.dispatch('getUserProfile')
    },
    cancelApplicationToGroup({ commit }, payload) {
      console.log(payload)
      fb.usersCollection.doc(payload.user.id).update({
        groupApplications: firebase.firestore.FieldValue.arrayRemove(payload.group.groupId),
      })
      fb.groupsCollection.doc(payload.group.groupId).update({
        applicants: firebase.firestore.FieldValue.arrayRemove(payload.user.id)
      })
      store.dispatch('getUserProfile')
    },
    
    clearGroupState({ commit }) {
      commit("setGroup", {})
      commit("setGroupShifts", [])
    },
    clearGroups({ commit }) {
      commit("setGroups", [])
    },


    /*VENUES*/
    addVenue({ commit }, payload) {
      fb.venuesCollection.add(payload)
      .then(
        doc => {
          fb.venuesCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    updateVenue({ commit }, payload) {
      fb.venuesCollection.doc(payload.id).update(payload)
    },
    deleteVenue({ commit }, payload) {
      fb.venuesCollection.doc(payload).delete()
    },
    getFeaturedVenues({ commit }) {
      console.log('getting featured venues')
      fb.venuesCollection.where('featured', '==', true).orderBy('title', 'asc')
      .get()
      .then(snapshot => {
        let venuesArray = []
        snapshot.forEach(doc => {
          let venue = doc.data()
          venuesArray.push(venue)
        })
        commit('setFeaturedVenues', venuesArray)
      })
    },
    getVenues({ commit }) {
      fb.venuesCollection.where('visible', '==', true).orderBy('title', 'asc')
      .get()
      .then(snapshot => {
        let venuesArray = []
        snapshot.forEach(doc => {
          let venue = doc.data()
          venuesArray.push(venue)
        })
        commit('setVenues', venuesArray)
      })
    },

    //   .onSnapshot(querySnapshot => {
    //     let venuesArray = []
    //     querySnapshot.forEach(doc => {
    //       let venue = doc.data()
    //       venue.id = doc.id
    //       venuesArray.push(venue)
    //     })
    //     let total = venuesArray.length
    //     commit('setVenues', venuesArray)
    //   })
    // },
    getVenuesTotal({ commit }) {
      fb.totalsCollection.doc("TazrjXQXkd0o0MGfU9tr").get()
      .then(doc => {
        console.log(doc.data())
        commit('setVenuesTotal', doc.data().numberOfVenues)  
      })
    },
    clearTotalsState({commit}, payload) {
      commit('setVenuesTotal', '') 
      commit('setUsersTotal', '')  
    },
    getVenueFromId({ commit }, payload) {
      console.log(payload)
      fb.venuesCollection.doc(payload).get()
      .then(
        doc => {
          store.dispatch('getVenueEvents', payload)
          commit("setVenueInfo", doc.data())
          commit("setVenueJobs", doc.data().job)
        }
      )
    },
    getVenueFromCheckin({ commit }, payload) {
      console.log(payload)
      fb.venuesCollection.doc(payload).get()
      .then(
        doc => {
          commit("setVenueInfo", doc.data())
        }
      )
      store.dispatch('getVenueCheckinEvent', payload)
    },
    clearVenueFromCheckin({ commit }) {
      commit("setVenueInfo", {})
      commit("setVenueCheckInEvent", {})
      commit("setEventUsers", [])
    },
    getVenueEventsSearchResults({ commit }, payload) {
      fb.eventsCollection.where("venueId", "==", payload).orderBy('startDate', 'asc')
      .get()
      .then(snapshot => {
        let yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1);
        let venueEventsArray = []
        snapshot.forEach(doc => {
          let startComp = new Date(doc.data().startDate)
          // let endComp = new Date(doc.data().endDate)
          console.log(startComp, yesterday)
          let event = doc.data()
          if (startComp >= yesterday) {
            venueEventsArray.push(event)
          }
        })
        commit('setVenueEventsSearchResults', venueEventsArray.slice(0,15))
      })
    },


    //   .onSnapshot(querySnapshot => {
    //     let yesterday = new Date()
    //     yesterday.setDate(yesterday.getDate() - 1);
    //     let venueEventsArray = []
    //     querySnapshot.forEach((doc) => {
    //       let startComp = new Date(doc.data().startDate)
    //       let endComp = new Date(doc.data().endDate)
    //       let event = doc.data()
    //       if ((endComp >= yesterday || startComp >= yesterday)) {
    //         venueEventsArray.push(event)
    //       }
    //     })
    //     commit('setVenueEventsSearchResults', venueEventsArray)
    //   })
    // },
    clearVenueEventsSearchResults({ commit }) {
      commit('setVenueEventsSearchResults', [])
    },
    // getVenueEvents({ commit }, payload) {
    //   fb.eventsCollection.where("venueId", "==", payload).orderBy('startDate', 'desc').onSnapshot(querySnapshot => {
    //     console.log(payload)
    //     let eventsArray = []

    //     if (querySnapshot.empty) {
    //       console.log('No matching documents.')
    //       commit('setVenueEvents', null)
    //     }

    //     querySnapshot.forEach(doc => {
    //       let compdate = new Date(doc.data().days[0])
    //       let today = new Date
    //       today.setDate(today.getDate() + 30);
    //       let event = doc.data()
    //       event.id = doc.id

    //       if (doc.data().published) {
    //         eventsArray.push(event)
    //       } else {
    //       }
          
    //     })
    //     if (eventsArray.length > 0) {
    //       commit('setVenueEvents', eventsArray.sort((a, b) => a.days[0] > b.days[0] ? 1 : a.days[0] === b.days[0] ? 0 : -1))
    //     }
    //   })
    // },

    getDayAssignment({ commit }, payload) {
      console.log(payload);
      let assCol = []
      fb.assignmentsCollection
        .where("eventId", "==", payload.event)
        .where("userId", "==", payload.user)
        .orderBy("day", "desc")
        .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            if(doc.exists) {
              assCol.push(doc.data())
            }
          })
          console.log(assCol)
          if (assCol && assCol.length >= 1) {
            commit('setDayAssignment', assCol[0]);
          }
        })
     },
     clearDayAssignment({ commit }) {
      commit('setDayAssignment', null);
     },
     async updateAssignmentTotal({ commit }, payload) {
      try {
        const updatePayrollRowTotal = firebase.functions().httpsCallable('updatePayrollRowTotal');
        const response = await updatePayrollRowTotal({
          assignmentId: payload.id
        });
        
      } catch (error) {
        console.error("Error updating payroll row total:", error);
      }
    },

    
    // getStaffFromAssignment({ commit }, payload) {
    //   console.log(payload);
    //   const d = new Date();
    //   const month = String(d.getMonth() + 1).padStart(2, '0');
    //   const day = String(d.getDate()).padStart(2, '0');
    //   const year = d.getFullYear();

    //   const formattedDate = [year, month, day].join('-');
    //   console.log(formattedDate);

    //   const uniqueEventUsersMap = {}; // Use an object or Map to store unique event users

    //   fb.assignmentsCollection
    //     .where("eventId", "==", payload)
    //     .onSnapshot(querySnapshot => {
    //       console.log('snapping');
    //       querySnapshot.forEach(doc => {
    //         const eventUser = doc.data();
    //         const userId = eventUser.userId;

    //         if (!uniqueEventUsersMap[userId]) {
    //           // If the user is not already in the map, add them
    //           uniqueEventUsersMap[userId] = eventUser;
    //           console.log(doc.id);
    //         }
    //       });

    //       const eventUsersArray = Object.values(uniqueEventUsersMap); // Convert the map values to an array
    //       commit('setEventUsers', eventUsersArray);
    //       // commit("setVenueCheckInEvent", eventUsersArray[0].eventInfo);
    //     });
    // },
     getStaffFromAssignment({ commit }, payload) {
      console.log(payload);
      const d = new Date();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      const year = d.getFullYear();

      const formattedDate = [year, month, day].join('-');
      console.log(formattedDate);

      const uniqueEventUsersMap = {}; // Use an object or Map to store unique event users

      fb.assignmentsCollection
        .where("eventId", "==", payload)
        .onSnapshot(querySnapshot => {
          console.log('snapping');
          querySnapshot.forEach(doc => {
            const eventUser = doc.data();
            const userId = eventUser.userId;
            console.log(eventUser);

            // Check if the event user has the "shift.shiftId" property
            if (!uniqueEventUsersMap[userId]) {
              // If the user is not already in the map and has the "shift.shiftId" property, add them
              uniqueEventUsersMap[userId] = eventUser;
              console.log(doc.id);
            }
          });

          const eventUsersArray = Object.values(uniqueEventUsersMap); // Convert the map values to an array
          commit('setEventUsers', eventUsersArray);
          // commit("setVenueCheckInEvent", eventUsersArray[0].eventInfo);
        });
    },
    yesBreakFromAssignment({ commit }, payload) {
      fb.assignmentsCollection.doc(payload.assignment).update({
        breakTime: payload.breakTime
      })
    },
    noBreakFromAssignment({ commit }, payload) {
      fb.assignmentsCollection.doc(payload.assignment).update({
        breakTime: payload.breakTime
      })
    },
    checkOutFromAssignment({ commit }, payload) {
      fb.assignmentsCollection.doc(payload.assignment).update({
        outTime: payload.out
      })
    },
    checkInFromAssignment({ commit }, payload) {
      fb.assignmentsCollection.doc(payload.assignment).update({
        inTime: payload.in
      })
    },
    getVenueCheckinFromAssignment({ commit }, payload) {
      console.log(payload)
      var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      var formattedDate = [year, month, day].join('-')
      console.log(formattedDate)

      fb.assignmentsCollection.where("eventInfo.venueId", "==", payload).where("day", "==", formattedDate)
      .onSnapshot(querySnapshot => {
        let eventUsersArray = []
        console.log('snapping')
        querySnapshot.forEach(doc => {

          eventUsersArray.push(doc.data())
          console.log(doc.id)
          
          // store.dispatch('getEventUsers2', ({
          //   day: formattedDate,
          //   event: doc.data().event
          //   })
          // )
        })
        commit('setEventUsers', eventUsersArray)
        commit("setVenueCheckInEvent", eventUsersArray[0].eventInfo)
      })
    },

    checkoutAssignment({ commit }, payload) {
      fb.userDaysCollection.where("userId", "==", payload.user.userId).where("event", "==", payload.user.event).get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          fb.userDaysCollection.doc(doc.id).update({
          checkOutTimeStamp: fb.firestore.FieldValue.serverTimestamp()
          })
        })
      })
    },



    checkinAssignment({ commit }, payload) {
      fb.userDaysCollection.where("userId", "==", payload.user.userId).where("event", "==", payload.user.event).get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          fb.userDaysCollection.doc(doc.id).update({
          checkInTimeStamp: fb.firestore.FieldValue.serverTimestamp()
          })
        })
      })
    },

        
    // getVenueCheckinEvent({ commit }, payload) {
    //   console.log(payload)
    //   var d = new Date(),
    //     month = '' + (d.getMonth() + 1),
    //     day = '' + d.getDate(),
    //     year = d.getFullYear();

    //     if (month.length < 2) 
    //         month = '0' + month;
    //     if (day.length < 2) 
    //         day = '0' + day;

    //     var formattedDate = [year, month, day].join('-')
    //     console.log(formattedDate)

    //   fb.eventsCollection.where("venueId", "==", payload).where("days", "array-contains", formattedDate).get()
    //   .then(snapshot => {
    //     snapshot.forEach(doc => {
    //       commit("setVenueCheckInEvent", doc.data())
    //       store.dispatch('getEventUsers', ({
    //         day: formattedDate,
    //         event: doc.data()
    //         })
    //       )
    //     })
    //   })
    // },
    // getEventUsers2({ commit }, payload) {
    //   console.log(payload)
    //   fb.assignmentsCollection.where("event", "==", payload.event).where("day", "==", payload.day).orderBy('created', 'asc')
    //   .onSnapshot(querySnapshot => {
    //     let eventUsersArray = []
    //     querySnapshot.forEach(doc => {
    //       let user = doc.data()
    //       eventUsersArray.push(user)
    //     })
    //     commit('setEventUsers', eventUsersArray)
    //   })
    // },
    async getEventUsers({ commit }, payload) {
      console.log('getEventUsers');
      console.log(payload);

      try {
        const query = fb.userDaysCollection.where("preferredEvent", "==", payload);

        query.onSnapshot((querySnapshot) => {
          const eventUsersArray = [];

          querySnapshot.forEach((doc) => {
            eventUsersArray.push(doc.data());
          });

          commit('setEventUsers', eventUsersArray);
        });
      } catch (error) {
        console.error('Error fetching event users:', error);
        // Handle the error as needed
      }
    },
    clearEventUsers({ commit }) {
      commit('setEventUsers', []);
    },
    
    // getEventUsers({ commit }, payload) {
    //   fb.userDaysCollection.where("preferredEvent", "==", payload.event.id).where("day", "==", payload.day).orderBy('created', 'asc')
    //   .onSnapshot(querySnapshot => {
    //     let eventUsersArray = []
    //     querySnapshot.forEach(doc => {
    //       let user = doc.data()
    //       eventUsersArray.push(user)
    //     })
    //     commit('setEventUsers', eventUsersArray)
    //   })
    // },
    // getVenueEvents({ commit }, payload) {
    //   fb.eventsCollection.where("venueId", "==", payload).orderBy('startDate', 'asc')
    //   .get()
    //   .then(snapshot => {
    //     let yesterday = new Date()
    //     yesterday.setDate(yesterday.getDate() - 1);
    //     let venueEventsArray = []
    //     snapshot.forEach(doc => {
    //       // console.log(doc.id)

    //       let endComp
    //       let startComp = new Date(doc.data().startDate)
    //       if (doc.data().endDate) {
    //        endComp= new Date(doc.data().endDate)
    //       }
    //       let event = doc.data()

    //       if ((endComp && endComp >= yesterday) || startComp >= yesterday) {
    //         venueEventsArray.push(event)
    //       }

 
    //     })
    //     commit('setVenueEvents', venueEventsArray.slice(0,15))
    //   })
    // },


        //   console.log("Parent Document ID: ", doc.id);

        //   let subCollectionDocs = eventsRef.doc(doc.id/{venue}).collection("mgrs").get()
        //     .then(snapshot => {
        //       snapshot.forEach(doc => {
        //         console.log("Sub Document ID: ", doc.id);
        //       })
        //     }).catch(err => {
        //       console.log("Error getting sub-collection documents", err);
        //     })
        // });

    //   .onSnapshot(querySnapshot => {
    //     console.log(payload)
        

    //     let yesterday = new Date()
    //     yesterday.setDate(yesterday.getDate() - 1);
    //     let venueEventsArray = []
    //     querySnapshot.forEach((doc) => {
    //       let startComp = new Date(doc.data().startDate)
    //       let endComp = new Date(doc.data().endDate)
    //       let event = doc.data()
    //       if ((endComp >= yesterday || startComp >= yesterday)) {
    //         venueEventsArray.push(event)
    //       }
    //     })
    //     commit('setVenueEvents', venueEventsArray)
    //   })
    // },

    clearVenueState({ commit }) {
      commit('setVenueInfo', {})
      commit('setVenueEvents', null)
      commit('setVenueEvents', [])
      commit('setVenueEvent', {})
      commit("setVenueJobs", [])
    },
    clearVenuesState({ commit }) {
      commit('setVenues', [])
    },


    /*Application*/


    // addAvailablityUpdate({ commit }, payload) {
    //   console.log(payload)
    //   fb.userDaysCollection.doc(payload.id).update({
    //     preferredEvent: payload.shift.eventId,
    //     shift: payload.shift.id,
    //     requestedShift: payload.shift,
    //     created: fb.firestore.FieldValue.serverTimestamp(),
    //     eventTitle: payload.event.title,
    //     eventSlug: payload.event.slug,
    //     day: payload.day,
    //   })
    // },
    async addAvailabilityUpdate({ commit }, payload) {
      try {
        console.log(payload);
        await fb.userDaysCollection.doc(payload.id).update({
          preferredEvent: payload.shift.eventId,
          shift: payload.shift.id,
          requestedShift: payload.shift,
          created: fb.firestore.FieldValue.serverTimestamp(),
          eventTitle: payload.event.title,
          eventSlug: payload.event.slug,
          day: payload.day,
        });
      } catch (error) {
        console.error(error);
        // Handle any errors that may occur during the Firestore update
      }
    },


    // async switchAvailablity({ commit }, payload) {
    //   console.log(payload)
    //   await fb.eventsCollection.doc(payload.shift.eventId).get()
    //   .then(doc => {
    //     let eventTitle = doc.data().title;
    //     let eventSlug = doc.data().slug;
    //     fb.userDaysCollection.doc(payload.id).update({
    //       preferredEvent: payload.shift.eventId,
    //       shift: payload.shift.id,
    //       requestedShift: payload.shift,
    //       created: fb.firestore.FieldValue.serverTimestamp(),
    //       eventTitle: eventTitle,
    //       eventSlug: eventSlug,
    //       day: payload.day,
    //     })
    //   })
    // },

    async switchAvailability({ commit }, payload) {
      try {
        console.log(payload);
        const eventDoc = await fb.eventsCollection.doc(payload.shift.eventId).get();

        if (eventDoc.exists) {
          const { title: eventTitle, slug: eventSlug } = eventDoc.data();
          await fb.userDaysCollection.doc(payload.id).update({
            preferredEvent: payload.shift.eventId,
            shift: payload.shift.id,
            requestedShift: payload.shift,
            created: fb.firestore.FieldValue.serverTimestamp(),
            eventTitle,
            eventSlug,
            day: payload.day,
          });
        }
      } catch (error) {
        console.error(error);
        // Handle any errors that may occur during the Firestore update or document retrieval
      }
    },


    // async addJobAvailablity({ commit }, payload) {
    //   console.log(payload)
    //   await fb.userDaysCollection.add({
    //     preferredEvent: payload.shift.eventId,
    //     day: payload.shift.day,
    //     start: payload.shift.day,
    //     status: "available",
    //     shift: payload.shift.id,
    //     requestedShift: payload.shift,
    //     fullName: payload.user.firstName + ' ' + payload.user.lastName,
    //     created: fb.firestore.FieldValue.serverTimestamp(),
    //     userId: payload.user.id,
    //     phone: payload.user.phone,
    //     email: payload.user.email,
    //   }).then(
    //     doc => {
    //       fb.userDaysCollection.doc(doc.id).update({
    //       id: doc.id,
    //       eventTitle: payload.event.title,
    //       eventSlug: payload.event.slug,
    //       state: payload.user.address.state || null,
    //       blacklist: payload.user.blacklist || null,
    //       certs: payload.user.certs || null,
    //       groups: payload.user.groups || null,
    //       phoneVerified: payload.user.phoneVerified || null,
    //       emailVerified: payload.user.emailVerified || null,
    //       photoUrl: payload.user.photoUrl || null,
    //       points: payload.user.points || null,
    //       rating: payload.user.rating || null,
    //       shirtsize: payload.user.shirtsize || null,
    //       skills: payload.user.skills || null,
    //       fullyVaccinated: payload.user.fullyVaccinated || null,
    //       firstName: payload.user.firstName || null,
    //       lastName: payload.user.lastName || null,
    //       employeeOnboardingComplete: payload.user.employeeOnboardingComplete || null,
    //       employeeId: payload.user.employeeId || null,
    //       evereeOnboardingComplete: payload.user.evereeOnboardingComplete || null,
    //       workerId: payload.user.workerId || null,
    //     })
    //   })
    // },
    async addJobAvailabilityFromSignup({ commit, state }, payload) {
      try {
        console.log(payload);
        const newUserDayDoc = await fb.userDaysCollection.add({
          preferredEvent: payload.shift.eventId,
          day: payload.shift.day,
          start: payload.shift.day,
          status: "available",
          shift: payload.shift.id,
          requestedShift: payload.shift,
          fullName: payload.user.firstName + ' ' + payload.user.lastName,
          created: fb.firestore.FieldValue.serverTimestamp(),
          userId: state.currentUser.uid,
          phone: payload.user.phone,
          email: payload.user.email,
          type: payload.type,
          eventTitle: payload.event.title,
          eventSlug: payload.event.slug,
          userId: state.currentUser.uid
        });
        console.log(newUserDayDoc)
        const newDocId = newUserDayDoc.id;

        await fb.userDaysCollection.doc(newDocId).update({
          id: newDocId,
        });
        store.dispatch("addToPipeline", {
          eventId: payload.shift.eventId,
          userId: state.currentUser.uid,
          shift: payload.shift
        })
      } catch (error) {
        console.error(error);
        // Handle any errors that may occur during the Firestore operations
      }
    },
    async addJobAvailability({ commit }, payload) {
      try {
        console.log(payload);
        const newUserDayDoc = await fb.userDaysCollection.add({
          preferredEvent: payload.shift.eventId,
          day: payload.shift.day,
          start: payload.shift.day,
          status: "available",
          shift: payload.shift.id,
          requestedShift: payload.shift,
          fullName: payload.user.firstName + ' ' + payload.user.lastName,
          created: fb.firestore.FieldValue.serverTimestamp(),
          userId: payload.user.id,
          phone: payload.user.phone,
          email: payload.user.email,
          type: payload.type
        });
        console.log(newUserDayDoc)
        const newDocId = newUserDayDoc.id;

        await fb.userDaysCollection.doc(newDocId).update({
          id: newDocId,
          eventTitle: payload.event.title,
          eventSlug: payload.event.slug,
          state: payload.user.address?.state || null,
          blacklist: payload.user.blacklist || null,
          certs: payload.user.certs || null,
          groups: payload.user.groups || null,
          phoneVerified: payload.user.phoneVerified || null,
          emailVerified: payload.user.emailVerified || null,
          photoUrl: payload.user.photoUrl || null,
          points: payload.user.points || null,
          rating: payload.user.rating || null,
          shirtsize: payload.user.shirtsize || null,
          skills: payload.user.skills || null,
          fullyVaccinated: payload.user.fullyVaccinated || null,
          firstName: payload.user.firstName || null,
          lastName: payload.user.lastName || null,
          employeeOnboardingComplete: payload.user.employeeOnboardingComplete || null,
          employeeId: payload.user.employeeId || null,
          evereeOnboardingComplete: payload.user.evereeOnboardingComplete || null,
          workerId: payload.user.workerId || null,
        });
        store.dispatch("addToPipeline", {
          eventId: payload.shift.eventId,
          userId: payload.user.id,
          shift: payload.shift
        })
      } catch (error) {
        console.error(error);
        // Handle any errors that may occur during the Firestore operations
      }
    },
    async addToPipeline({ commit }, payload) {
      console.log('addToPipeline');
      console.log(payload);

      const userId = payload.userId;
      const eventId = payload.eventId;
      const shift = payload.shift || null;
      let position = []
      if (payload.shift.position) {
        position.push(payload.shift.position)
      }


      if (!userId || !eventId) {
        return;
      }

       const updatePipelineDetails = firebase.functions().httpsCallable('updatePipelineDetails');
        const updatePipelineShifts = firebase.functions().httpsCallable('updatePipelineShifts');

        await Promise.all([
          updatePipelineDetails({ userId, eventId }),
          updatePipelineShifts({ userId, eventId })
        ]);

      try {
        await Promise.all([
          fb.usersCollection.doc(userId).update({
            pipeline: firebase.firestore.FieldValue.arrayUnion(eventId),
            pipelineCancelled: firebase.firestore.FieldValue.arrayRemove(eventId),
            shiftPreference: firebase.firestore.FieldValue.arrayUnion(shift),
            positionPreference: position,
            cancellationReason: ''
          }),
          fb.eventsCollection.doc(eventId).update({
            pipeline: firebase.firestore.FieldValue.arrayUnion(userId),
            pipelineCancelled: firebase.firestore.FieldValue.arrayRemove(userId)
          })
        ]);

      } catch (error) {
        console.error(error);
      }
    },


    // async addAvailablity({ commit }, payload) {
    //   console.log(payload)
    //   await fb.userDaysCollection.add({
    //     preferredEvent: payload.shift.eventId,
    //     day: payload.day,
    //     start: payload.day,
    //     status: "placed",
    //     shift: payload.shift.id,
    //     requestedShift: payload.shift,
    //     fullName: payload.user.firstName + ' ' + payload.user.lastName,
    //     created: fb.firestore.FieldValue.serverTimestamp(),
    //     userId: payload.user.id,
    //     phone: payload.user.phone,
    //     email: payload.user.email,
    //   }).then(
    //     doc => {
    //       fb.userDaysCollection.doc(doc.id).update({
    //       id: doc.id,
    //       eventTitle: payload.event.title,
    //       eventSlug: payload.event.slug,
    //       state: payload.user.address.state || null,
    //       blacklist: payload.user.blacklist || null,
    //       certs: payload.user.certs || null,
    //       groups: payload.user.groups || null,
    //       phoneVerified: payload.user.phoneVerified || null,
    //       emailVerified: payload.user.emailVerified || null,
    //       photoUrl: payload.user.photoUrl || null,
    //       points: payload.user.points || null,
    //       rating: payload.user.rating || null,
    //       shirtsize: payload.user.shirtsize || null,
    //       skills: payload.user.skills || null,
    //       fullyVaccinated: payload.user.fullyVaccinated || null,
    //       firstName: payload.user.firstName || null,
    //       lastName: payload.user.lastName || null,
    //       employeeOnboardingComplete: payload.user.employeeOnboardingComplete || null,
    //       employeeId: payload.user.employeeId || null,
    //       evereeOnboardingComplete: payload.user.evereeOnboardingComplete || null,
    //       workerId: payload.user.workerId || null,
    //     })
    //   })
    // },
    async addAvailability({ commit }, payload) {
      try {
        console.log(payload);
        const newUserDayDoc = await fb.userDaysCollection.add({
          preferredEvent: payload.shift.eventId,
          day: payload.day,
          start: payload.day,
          status: "placed",
          shift: payload.shift.id,
          requestedShift: payload.shift,
          fullName: payload.user.firstName + ' ' + payload.user.lastName,
          created: fb.firestore.FieldValue.serverTimestamp(),
          userId: payload.user.id,
          phone: payload.user.phone,
          email: payload.user.email,
        });

        const newDocId = newUserDayDoc.id;

        await fb.userDaysCollection.doc(newDocId).update({
          id: newDocId,
          eventTitle: payload.event.title,
          eventSlug: payload.event.slug,
          state: payload.user.address?.state || null,
          blacklist: payload.user.blacklist || null,
          certs: payload.user.certs || null,
          groups: payload.user.groups || null,
          phoneVerified: payload.user.phoneVerified || null,
          emailVerified: payload.user.emailVerified || null,
          photoUrl: payload.user.photoUrl || null,
          points: payload.user.points || null,
          rating: payload.user.rating || null,
          shirtsize: payload.user.shirtsize || null,
          skills: payload.user.skills || null,
          fullyVaccinated: payload.user.fullyVaccinated || null,
          firstName: payload.user.firstName || null,
          lastName: payload.user.lastName || null,
          employeeOnboardingComplete: payload.user.employeeOnboardingComplete || null,
          employeeId: payload.user.employeeId || null,
          evereeOnboardingComplete: payload.user.evereeOnboardingComplete || null,
          workerId: payload.user.workerId || null,
        });
      } catch (error) {
        console.error(error);
        // Handle any errors that may occur during the Firestore operations
      }
    },


    




    /*USERS*/
    // getUsers({ commit }) {
    //   fb.usersCollection.onSnapshot(querySnapshot => {
    //     let usersArray = []
    //     querySnapshot.forEach(doc => {
    //       let user = doc.data()
    //       usersArray.push(user)
    //     })
    //     let total = usersArray.length
    //     commit('setUsers', usersArray)
    //   })
    // },
    // getUsersTotal({ commit }) {
    //   fb.totalsCollection.doc("TazrjXQXkd0o0MGfU9tr").get()
    //   .then(doc => {
    //     commit('setUsersTotal', doc.data().numberOfUsers)  
    //   })
    // },
    // getUserFromId({ commit }, payload) {
    //   fb.usersCollection.where("id", "==", payload).onSnapshot(querySnapshot => {
    //     querySnapshot.forEach(function (doc) {
    //       commit("setUserInfo", doc.data())
    //     })
    //   })
    // },
    // getUserReferrals({ commit, state }) {
    //   console.log(state.currentUser.uid)
    //   fb.referralsCollection.where("referrer_userId", "==", state.currentUser.uid)
    //   .get()
    //   .then(snapshot => {
    //     let referralsArray = []
    //     snapshot.forEach(doc => {
    //       let referral = doc.data()
    //       referralsArray.push(referral)
    //        })
    //     commit('setUserReferrals', referralsArray)
    //   })
    // },
    getUserTimesheets({ commit }, payload) {
      console.log("getting user timesheets")
      fb.assignmentsCollection.where("userId", "==", payload).orderBy('day', 'desc')
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let timesheetsArray = []
        querySnapshot.forEach(doc => {
          let sheet = doc.data()
          timesheetsArray.push(sheet)
        })
        commit('setUserTimesheets', timesheetsArray)
      })
    },
    clearUserTimesheets({ commit }) {
      commit('setUserTimesheets', [])
    },


    //   .onSnapshot(querySnapshot => {
    //     let referralsArray = []
    //     querySnapshot.forEach(doc => {
    //       let referral = doc.data()
    //       referralsArray.push(referral)
    //     })
    //     commit('setUserReferrals', referralsArray)
    //   })
    // },
    getUserVerifications({ commit, state }) {
      fb.verificationsCollection.where("userId", "==", state.currentUser.uid)
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let verificationsArray = []
        querySnapshot.forEach(doc => {
          let verification = doc.data()
          verificationsArray.push(verification)
        })
        commit('setVerifications', verificationsArray)
      })
    },
    // getUserAvailability({ commit, state }) {
    //   console.log('getting availability')
    //   fb.userDaysCollection.where("userId", "==", state.currentUser.uid)
    //   .onSnapshot(querySnapshot => {
    //     // .get().then(querySnapshot => {
    //     let availableDaysArray = []
    //     let availableDataArray = []

    //     // let yesterday = new Date()
    //     // let today = new Date()
    //     // yesterday.setDate(yesterday.getDate() - 1);
    //     // console.log(yesterday)
    //     querySnapshot.forEach(doc => {
    //       let day = doc.data().day
    //       let fullDoc = doc.data()
    //       availableDaysArray.push(day)
    //       availableDataArray.push(fullDoc)
    //     })
    //     // querySnapshot.forEach(doc => {
    //     //   let daye = new Date(doc.data().day)
    //     //   // today.setDate(daye.getDate());
    //     //   // let theDate = doc.data().day.getDate()
    //     //   if (daye >= yesterday) {
    //     //     let day = doc.data().day
    //     //     let fullDoc = doc.data()
    //     //     availableDaysArray.push(day)
    //     //     availableDataArray.push(fullDoc)
    //     //   }
    //     // })
    //     console.log(availableDaysArray)
    //     commit('setAvailableData', availableDataArray)
    //     commit('setAvailableDays', availableDaysArray)
    //   })
    // },
    getUserAvailability({ commit, state }) {
      console.log('getting availability');
      fb.userDaysCollection
        .where("userId", "==", state.currentUser.uid)
        .onSnapshot(querySnapshot => {
          let availableDaysArray = [];
          let availableDataArray = [];

          querySnapshot.forEach(doc => {
            let fullDoc = doc.data();
            availableDaysArray.push(fullDoc.day);
            availableDataArray.push(fullDoc);
          });

          console.log(availableDaysArray);
          commit('setAvailableData', availableDataArray);
          commit('setAvailableDays', availableDaysArray);
        });
    },
    getUserJobAvailability({ commit, state }) {
      console.log('getting job availability');
      fb.userDaysCollection
        .where("userId", "==", state.currentUser.uid)
        .onSnapshot(querySnapshot => {
          let appliedJobsArray = [];
          querySnapshot.forEach(doc => {
            console.log(doc.data())
            if (doc.data() && doc.data().type && doc.data().type == 'Job') {
              appliedJobsArray.push(doc.data())
            }
          })
          console.log(appliedJobsArray);
          commit('setAppliedJobs', appliedJobsArray);
        });
    },

    async retrieveWorkerPayHistory({ commit }, payload) {
      const retrieveWorkerPayHistory = firebase.functions().httpsCallable('retrieveWorkerPayHistory')
        await retrieveWorkerPayHistory({
        id: payload
      })
      .then(result => {
        console.log(result.data)
        commit('setWorkerPayHistory', result.data)
      })
    },
    async retrieveEmployeePayHistory({ commit }, payload) {
      const retrieveEmployeePayHistory = firebase.functions().httpsCallable('retrieveEmployeePayHistory')
        await retrieveEmployeePayHistory({
        id: payload
      })
      .then(result => {
        console.log(result.data)
        commit('setEmployeePayHistory', result.data)
      })
    },

    clearUserState({ commit }) {
      commit('setUserInfo', {})
      commit('setWorkerPayHistory', {})
      commit('setEmployeePayHistory', {})
      commit('setVerifications',[])
      commit('setUserReferrals', [])
    },
    clearUsersState({ commit }) {
      commit('setUsers', [])
    },

    /*PANELS*/
    getPanels({ commit }) {
      fb.panelsCollection.orderBy("state", "asc")
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let panelsArray = []
        querySnapshot.forEach((doc) => {
          panelsArray.push(doc.data())
        })
        commit('setPanels', panelsArray)
      })
    },
    clearPanels({ commit }) {
      commit('setPanels', [])
    },



    /*JOBS*/
    addJob({ commit }, payload) {
      fb.jobsCollection.add(payload)
      .then(
        doc => {
          fb.jobsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    getSkillCategories({ commit }) {
      fb.skillCategoriesCollection
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let catsArray = []
        querySnapshot.forEach(doc => {
          let cat = doc.data()
          catsArray.push(cat)
        })
        commit('setSkillCategories', catsArray)
      })
    },
    clearSkillCategories({ commit }) {
      commit('setSkillCategories', [])
    },
    getJobsState({ commit }) {
      fb.skillsCollection
      .get()
      .then(snapshot => {
        let jobsArray = []
        snapshot.forEach(doc => {
          let job = doc.data()
          jobsArray.push(job)
        })
        commit('setJobs', jobsArray)
      })
    },
    getJobsByCategory({ commit }, payload) {
      console.log(payload)
      fb.skillsCollection.where("category", "array-contains", payload)
      .get()
      .then(snapshot => {
        let jobsArray = []
        snapshot.forEach(doc => {
          let job = doc.data()
          jobsArray.push(job)
        })
        commit('setJobsByCategory', jobsArray)
      })
    },
    deleteSkill({ commit }, payload) {
      fb.usersCollection.doc(payload.user.id).update({
        skills: firebase.firestore.FieldValue.arrayRemove(payload.skill)
      })
    },
    addSkill({ commit }, payload) {
      fb.usersCollection.doc(payload.user.id).update({
        skills: firebase.firestore.FieldValue.arrayUnion(payload.skill)
      })
    },
    clearJobsByCategory({ commit }) {
      commit('setJobsByCategory', [])
    },

    //   .onSnapshot(querySnapshot => {
    //     let jobsArray = []
    //     querySnapshot.forEach(doc => {
    //       let job = doc.data()
    //       jobsArray.push(job)
    //     })
    //     commit('setJobs', jobsArray)
    //   })
    // },
    getJobFromId({ commit }, payload) {
      fb.skillsCollection.where("id", "==", payload)
      // .onSnapshot(querySnapshot => {
        .get().then(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          commit("setJobInfo", doc.data())
        })
      })
    },
    deleteJob({ commit }, payload) {
      fb.jobsCollection.doc(payload).delete()
    },
    updateJob({ commit }, payload) {
      fb.jobsCollection.doc(payload.id).update(payload)
    },
    clearJobState({ commit }) {
      commit('setJobInfo', {})
    },
    clearJobsState({ commit }) {
      commit('setJobs', [])
    },


    /*CHECKIN and OUT*/
    getCode({ commit }) {
      fb.checkInMasterCollection.doc('q6fwrK6k4RxLSw3NQN6y')
      .onSnapshot((doc) => {
        let checkInMaster = doc.data().checkInMaster
        commit('setCheckInMaster', checkInMaster)
      })
    },
    checkinSubmit({ commit }, payload) {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth()+1; 
      var yyyy = today.getFullYear();
      if (dd<10) { dd='0'+dd; } 
      if (mm<10) { mm='0'+mm; } 
      today = yyyy+'-'+mm+'-'+dd;
      console.log(today)
      fb.assignmentsCollection.where("userId", "==", payload.user.id).where("day", "==", today).get()
       .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          console.log(doc.data().id)
          fb.assignmentsCollection.doc(doc.data().id).update({
            checkInCheckOutStatus: 'checked-in',
            checkInTimeStamp: fb.firestore.FieldValue.serverTimestamp(),
          })
        })
       })
    },
    checkoutSubmit({ commit }, payload) {
      console.log(payload)
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth()+1; 
      var yyyy = today.getFullYear();
      if (dd<10) { dd='0'+dd; } 
      if (mm<10) { mm='0'+mm; } 
      today = yyyy+'-'+mm+'-'+dd;
      fb.assignmentsCollection.where("userId", "==", payload.user.id).where("day", "==", today).get()
       .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          console.log(doc.data().id)
          fb.assignmentsCollection.doc(doc.data().id).update({
            checkInCheckOutStatus: 'checked-out',
            checkOutBreaks: payload.breaks,
            checkOutNote: payload.note,
            checkOutTimeStamp: fb.firestore.FieldValue.serverTimestamp(),
          })
        })
       })
    },
    getCheckInOutStatus({ commit }, payload) {
      console.log(payload)
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth()+1; 
      var yyyy = today.getFullYear();
      if (dd<10) { dd='0'+dd; } 
      if (mm<10) { mm='0'+mm; } 
      today = yyyy+'-'+mm+'-'+dd;
      fb.assignmentsCollection.where("userId", "==", payload).where("day", "==", today).get()
       .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          if (!doc.data().checkInCheckOutStatus) {
            commit('setCanCheckIn', true)
          } else {
            commit('setCanCheckIn', false)
          }
        })
      })
    },


    /*CLIENTS*/
    // addClient({ commit }, payload) {
    //   fb.clientsCollection.add(payload)
    //   .then(
    //     doc => {
    //       fb.clientsCollection.doc(doc.id).update({
    //         id: doc.id,
    //         created: fb.firestore.FieldValue.serverTimestamp(),
    //       })
    //     }
    //   )
    // },
    // getClients({ commit }) {
    //   fb.clientsCollection.onSnapshot(querySnapshot => {
    //     let clientsArray = []
    //     querySnapshot.forEach(doc => {
    //       let client = doc.data()
    //       clientsArray.push(client)
    //     })
    //     commit('setClients', clientsArray)
    //   })
    // },
    // getClientFromId({ commit }, payload) {
    //   fb.clientsCollection.where("id", "==", payload).onSnapshot(querySnapshot => {
    //     querySnapshot.forEach(function (doc) {
    //       commit("setClientInfo", doc.data())
    //     })
    //   })
    // },
    // deleteClient({ commit }, payload) {
    //   fb.clientsCollection.doc(payload).delete()
    // },
    // updateClient({ commit }, payload) {
    //   fb.clientsCollection.doc(payload.id).update(payload)
    // },
    // clearClientState({ commit }) {
    //   commit('setClientInfo', {})
    // },
    // clearClientsState({ commit }) {
    //   commit('setClients', [])
    // },


    /*PIPELINE*/
    getPipelineCancelled({ commit }, payload) {
      console.log("getPipelineCancelled")
      let membersArray = []
      fb.eventsCollection.doc(payload)
      .get()
      .then(
        doc => {
          if (doc.exists && doc.data().pipelineCancelled && doc.data().pipelineCancelled.length > 0) { 
            doc.data().pipelineCancelled.forEach(pipe => {
              fb.usersCollection.doc(pipe).get()
              .then(
                doc => {
                  if(doc.exists) {
                    membersArray.push(doc.data())
                  }
                }
              )
            })
            commit('setPipelineCancelled', membersArray)
          }
        })
    },


    clearPipelineCancelled({ commit }) {
      commit('setPipelineCancelled', [])
    },

    getPipelineHired({ commit }, payload) {
      console.log("getPipelineHired")
      let membersArray = []
      fb.eventsCollection.doc(payload)
      .get()
      .then(
        doc => {
          if (doc.exists && doc.data().pipelineHired && doc.data().pipelineHired.length > 0) { 
            doc.data().pipelineHired.forEach(pipe => {
              fb.usersCollection.doc(pipe).get()
              .then(
                doc => {
                  if(doc.exists) {
                    membersArray.push(doc.data())
                  }
                }
              )
            })
            commit('setPipelineHired', membersArray)
          }
        })
    },


    clearPipelineHired({ commit }) {
      commit('setPipelineHired', [])
    },
    updatePipelineNote({ commit }, payload) {
      fb.usersCollection.doc(payload.id).update({
        pipelineNote: payload.pipelineNote
      })
    },
    getPipeline({ commit }, payload) {
      console.log("getPipeline")
      let membersArray = []
      fb.eventsCollection.doc(payload)
      .get()
      .then(
        doc => {
          if (doc.exists && doc.data().pipeline && doc.data().pipeline.length > 0) { 
            doc.data().pipeline.forEach(pipe => {
              fb.usersCollection.doc(pipe).get()
              .then(
                doc => {
                  if(doc.exists) {
                    membersArray.push(doc.data())
                  }
                }
              )
            })
            commit('setPipeline', membersArray)
          }
        })
    },
    clearPipeline({ commit }) {
      commit('setPipeline', [])
    },


    /*Verifications*/
    addVerification({ commit }, payload) {
      fb.verificationsCollection.add(payload)
      .then(
        doc => {
          fb.verificationsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    updateVerification({ commit }, payload) {
      console.log(payload)
      fb.verificationsCollection.doc(payload.id).update(payload)
    },
    deleteVerification({ commit }, payload) {
      fb.verificationsCollection.doc(payload).delete()
    },

    /*LEAD SUBMISSION*/
    newLeadSubmission({ commit }, payload) {
      console.log(payload)
      fb.marketingLeadsCollection.add(payload)
      .then(
        doc => {
          console.log(doc.id)
          fb.marketingLeadsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    deleteLead({ commit }, payload) {
      fb.marketingLeadsCollection.where("email", "==", payload.email).get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          fb.marketingLeadsCollection.doc(doc.id).delete()
        })
      })
    },





    /*EVENTS*/
    getPayFAQ({ commit }) {
      fb.faqsCollection.doc("tVBRhsl2TmPhma5ACAs7").get()
      .then(
      doc => {
        commit('setPayFAQ', doc.data())
      })
    },
    addEvent({ commit }, payload) {
      fb.eventsCollection.add(payload)
      .then(
        doc => {
          fb.eventsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
            venueId: payload.venue.id,
            jobs: [],
            days: []
          })
        }
      )
    },
    updateEventVenue({ commit }, payload) {
      console.log(payload)
      fb.eventsCollection.doc(payload.id).update(payload)
    },
    updateEvent({ commit }, payload) {
      console.log(payload)
      fb.eventsCollection.doc(payload.id).update(payload)
    },
    deleteEvent({ commit }, payload) {
      fb.eventsCollection.doc(payload).delete()
    },

    

    // getInfiniteEvents({ commit }) {
    //   const eventsRef = fb.eventsCollection.where("published", "==", true)
    //   var lastVisibleEventSnapShot = {};
    //   const query = eventsRef.orderBy('startDate', 'asc').limit(12);
    //   query.get().then(snap => {
    //     let yesterday = new Date()
    //     yesterday.setDate(yesterday.getDate() - 1);
    //     let currentEventsArray = []
    //     snap.forEach(doc => {
    //       let startComp = new Date(doc.data().startDate)
    //       let endComp = new Date(doc.data().endDate)
    //       let event = doc.data()
    //       if ((endComp >= yesterday || startComp >= yesterday)) {
    //         currentEventsArray.push(event)
    //       }
    //     });
    //     commit('setInfiniteEvents', currentEventsArray)
    //     lastVisibleEventSnapShot = snap.docs[snap.docs.length - 1];
    //     commit('setlLastVisibleEventSnapShot', lastVisibleEventSnapShot)
    //   });
    // },
    // getSearchEvents({ commit }, payload) {
    //   console.log('getting search')
    //   fb.eventsCollection.where("published", "==", true).orderBy('startDate', 'asc').where("title")
    // },
    // getTagsState({ commit }, payload) {
    //   fb.tagsCollection.onSnapshot(querySnapshot => {
    //     let tagsArray = []
    //     querySnapshot.forEach(doc => {
    //       let tag = doc.data()
    //       tagsArray.push(tag)
    //     })
    //     commit('setTags', tagsArray)
    //   })
    // },
    // clearTagsState({ commit }) {
    //   commit('setTags', [])
    // },
    // getTaggedEvents({ commit }, payload) {
    //   console.log(payload)
    //   console.log('getting tagged')
    //   fb.eventsCollection.where("published", "==", true).where("tags", "array-contains", payload).orderBy('startDate', 'asc')
    //   .get()
    //   .then(snapshot => {
    //     var lastVisibleEventSnapShot = {};
    //     let yesterday = new Date()
    //     yesterday.setDate(yesterday.getDate() - 1);
    //     let taggedEventsArray = []
    //     snapshot.forEach(doc => {
    //       let startComp = new Date(doc.data().startDate)
    //       let endComp = new Date(doc.data().endDate)
    //       let event = doc.data()
    //       if ((endComp >= yesterday || startComp >= yesterday)) {
    //         taggedEventsArray.push(doc.data())
    //       }
    //     })
    //     commit('setTaggedEvents', taggedEventsArray)
    //   })
    // },

    //   .onSnapshot(querySnapshot => {
    //     var lastVisibleEventSnapShot = {};
    //     let yesterday = new Date()
    //     yesterday.setDate(yesterday.getDate() - 1);
    //     let taggedEventsArray = []
    //     querySnapshot.forEach((doc) => {
    //       let startComp = new Date(doc.data().startDate)
    //       let endComp = new Date(doc.data().endDate)
    //       let event = doc.data()
    //       if ((endComp >= yesterday || startComp >= yesterday)) {
    //         taggedEventsArray.push(doc.data())
    //       }
    //     })
    //     commit('setTaggedEvents', taggedEventsArray)
    //   })
    // },
    clearTaggedEvents({ commit }) {
      commit('setTaggedEvents', [])
    },


    // async getAvailableShifts({ commit, state }) {
    //   // console.log('getAvailableShifts')
    //   await fb.usersCollection.doc(state.currentUser.uid).get()
    //   .then(doc => {
    //     let shiftsArray = []
    //     //Get each group a user is a member of
    //     if (doc.data() && doc.data().groups) {
    //       doc.data().groups.forEach(group => {
    //         // Get each shift with access restricted to that group
    //         fb.shiftsCollection.where("groupIds", "array-contains", group).orderBy('day', 'asc').onSnapshot(querySnapshot => {
    //           let queue = []

    //           querySnapshot.forEach(doc => {
    //             let yesterday = new Date()
    //             yesterday.setDate(yesterday.getDate() - 1);
    //             let startComp = new Date(doc.data().day)
    //             if (startComp.getTime() >= yesterday.getTime()) {
    //               queue.push(doc.data())
    //             }
    //           })
    //           //add shift with group access to queue
    //           queue.forEach(item => {
    //             if (!item.status || item.status == 'Open') {
    //               shiftsArray.push(item)
    //             }
                
    //           })
    //           let uniqueShifts = [...new Set(shiftsArray)]
    //         })
    //         // shiftsArray.push(doc.data())
    //       })
    //       console.log(shiftsArray)
    //       commit('setAvailableShifts', shiftsArray)
    //     }
    //   })
    // },

    // async getAvailableShiftsWithoutGroups({ commit, state }) {
    //   // console.log('getAvailableShiftsWithoutGroups')
    //   await fb.shiftsCollection.orderBy('day', 'asc').onSnapshot(querySnapshot => {
    //     let shiftsArray = []
    //     querySnapshot.forEach(doc => {
    //       let yesterday = new Date()
    //       yesterday.setDate(yesterday.getDate() - 1);
    //       let startComp = new Date(doc.data().day)
    //       if ((startComp.getTime() >= yesterday.getTime()) && (!doc.data().groups || doc.data().groups.length == 0) && (!doc.data().status || doc.data().status == 'Open')) {
    //         shiftsArray.push(doc.data())
    //       }
    //     })
    //     // console.log(shiftsArray)
    //     commit('setAvailableShiftsWithoutGroups', shiftsArray)
    //     // store.dispatch("getAllMyShifts")
    //   })
      
    // },

    async getAvailableShiftsWithoutGroups({ commit, state }) {
      // console.log('getAvailableShiftsWithoutGroups')
      await fb.shiftsCollection.orderBy('day', 'asc')
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let shiftsArray = []
        querySnapshot.forEach(doc => {
          let yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1);
          let startComp = new Date(doc.data().day)
          if ((startComp.getTime() >= yesterday.getTime()) && (!doc.data().status || doc.data().status == 'Open') && (!doc.data().eventStatus || doc.data().eventStatus == 'Open') && (!doc.data().type || doc.data().type != 'Job')) {
            shiftsArray.push(doc.data())
          }
        })
        // console.log(shiftsArray)
        commit('setAvailableShiftsWithoutGroups', shiftsArray)
        // store.dispatch("getAllMyShifts")
      })
      
    },

    // async getAllMyShifts({ commit, state }) {
    //   let allArray = [...state.availableShifts, ...state.availableShiftsWithoutGroups]

    //   await allArray.sort((a, b) => {
    //     return Date.parse(a.day) - Date.parse(b.day);
    //   })

    //   commit('setAllAvailableShifts', allArray)
    // },


    // getAllAvailableShifts({ commit, state }) {
    //   console.log(state.availableShifts)
    //   commit('setAvailableShifts', [...state.availableShifts, ...state.availableShiftsWithoutGroups])
    // },

    // async getMyShifts({ commit, state }) {
    //   await fb.userDaysCollection.where("userId", "==", state.currentUser.uid).where("status", "==", 'assigned').onSnapshot(querySnapshot => {
    //     let daysArray = []
    //     querySnapshot.forEach(doc => {
    //       let yesterday = new Date()
    //       yesterday.setDate(yesterday.getDate() - 1);
    //       let startComp = new Date(doc.data().day)
    //       if (startComp.getTime() >= yesterday.getTime()) {
    //         daysArray.push(doc.data())
    //       } else {}

    //       daysArray.forEach(day => {
    //         if (day.shift) {
    //           console.log(day)
    //           return fb.shiftsCollection.where("id", "==", day.shift).onSnapshot(querySnapshot => {
    //             let shiftsArray = []
    //             console.log(doc.data())
    //             querySnapshot.forEach(doc => {
    //               shiftsArray.push(doc.data())
    //             })
    //             commit('setMyShifts', shiftsArray)
    //             console.log(shiftsArray)
    //           })
    //         }
    //       })
    //     })
    //   })
    // },

    getMyAssignment({ commit, state }, payload) {
      console.log('getMyAssignments')
      fb.assignmentsCollection.where("userId", "==", state.currentUser.uid).orderBy("day", "desc")
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let assignmentsArray = []
        querySnapshot.forEach(doc => {
          let shift = doc.data()
          assignmentsArray.push(shift)
        })
        commit('setMyAssignment', assignmentsArray[0])
      })
    },

    getMyShifts({ commit, state }) {
      fb.assignmentsCollection.where("userId", "==", state.currentUser.uid).orderBy("day", "desc")
      // .onSnapshot(querySnapshot => {
        .get().then(querySnapshot => {
        let shiftsArray = []
        querySnapshot.forEach(doc => {
          console.log(doc.data())
          let yesterday = new Date()

          yesterday.setDate(yesterday.getDate() - 1);
            let startComp = new Date(doc.data().day)
            if (!doc.data().removed) {
              shiftsArray.push(doc.data())
            }
        })

        const uniqueItems = [...new Map(shiftsArray.map(v => [v.shiftId, v])).values()]
        const uniqueShifts = []
        uniqueItems.forEach(item => {
          console.log(item)
          uniqueShifts.push(item.shift)
        })


        commit('setMyShifts', uniqueShifts)
      })
    },

    clearShiftLists({commit}) {
      commit('setMyShifts', [])
      commit('setAvailableShifts', [])
      commit('setAvailableShiftsWithoutGroups', [])
    },
    
    getShiftFromId({ commit }, payload) {
      console.log(payload)
      fb.shiftsCollection.doc(payload).get()
      .then(doc => {
        console.log(doc.data())
        if (doc.data().eventId) {
          store.dispatch('getEventFromIdAss', doc.data().eventId)
        }
        commit("setShiftInfo", doc.data())
      })
      // store.dispatch('getActiveShiftAssignments', payload)
      
    },
    clearShift({commit}) {
      commit("setShiftInfo", {})
    },



    getUserApplication({ commit }, payload) {
      console.log(payload)
      fb.applicationsCollection
      .where("eventId", "==", payload.event)
      .where("userId", "==", payload.user)
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.empty) {
          commit("setUserEventStatus", 'open')
        }
        querySnapshot.forEach(function (doc) {
          commit("setUserEventStatus", doc.data().status)
        })
      })
    },
    // getEventShifts({ commit }, payload) {
    //   console.log(payload)
    //   fb.shiftsCollection.where("eventId", "==", payload).orderBy('day', 'desc').onSnapshot(querySnapshot => {
    //     let shiftsArray = []
    //     querySnapshot.forEach(doc => {
    //       let shift = doc.data()
    //       shift.id = doc.id
    //       shiftsArray.push(shift)
    //     })
    //     commit('setEventShifts', shiftsArray)
    //   })
    // },
    // getEventFromVenue({ commit }, payload) {
    //   console.log(payload)
    //   fb.eventsCollection.where("days", "array-contains", payload.day).where("venueId", "==", payload.venue.id).onSnapshot(querySnapshot => {
    //     let eventsArray = []
    //     querySnapshot.forEach(doc => {
    //       let event = doc.data()
    //       eventsArray.push(event)
    //     })
    //     commit("setVenueEvent", eventsArray[0])
    //   })
    // },
    getEventFromShiftId({ commit }, payload) {
      console.log(payload)
      fb.eventsCollection.doc(payload).get()
      .then(doc => {
        // console.log(doc.data())
        commit("setEventInfo", doc.data())
      })
      // store.dispatch('getActiveShiftAssignments', payload)
    },
    getEventFromIdOnly({ commit }, payload) {
      console.log(payload)
      fb.eventsCollection.where("id", "==", payload).onSnapshot(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          commit("setEventInfo", doc.data())
        })
      })
      store.dispatch('getEventShifts', payload)
    },
    async getEventFromIdAss({ commit }, payload) {
      console.log(payload)
      await fb.eventsCollection.doc(payload).get()
      .then(doc => {
        commit("setEventInfo", doc.data())
      })
    },
    async getOrderFromId({ commit }, payload) {
      console.log(payload)
      await fb.eventsCollection.where("id", "==", payload).onSnapshot(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          commit("setEventInfo", doc.data())
        })
      })
    },
    getUserDayFromAssignment({ commit }, payload) {
      console.log(payload)
      fb.userDaysCollection.where("userId", "==", payload.userId).where("shift", "==", payload.shiftId)
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          console.log(doc.data())
          commit("setAssignmentUser", doc.data())
        })
      })
    },
    confirmAssignment({ commit }, payload) {
      console.log(payload)
      fb.userDaysCollection.where("day", "==", payload.day).where("userId", "==", payload.userId).where("shift", "==", payload.shiftId)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          console.log(doc.id)
          fb.userDaysCollection.doc(doc.id).update({
            confirmed: true
          })
        })
      })
    },
    getEventFromSlug({ commit }, payload) {
      console.log(payload)
      fb.eventsCollection.where("slug", "==", payload)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          commit("setEventInfo", doc.data())
          store.dispatch('getVenueFromId', doc.data().venueId)
          store.dispatch('getActiveAssignment', doc.data().id)
          store.dispatch('getEventShifts', doc.data().id)
        })
      })
      
    },
    getPreferredEvents({ commit, state }, payload) {
      console.log(payload)
      fb.userDaysCollection.where("userId", "==", state.currentUser.uid).orderBy('start', 'asc')
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let eventsArray = []
        querySnapshot.forEach(doc => {
          if (doc.data().preferredEvent && doc.data().preferredEvent != false) {
            let event = doc.data()
            eventsArray.push(event)
          }
        })

        let myEvents = []
        let yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1.5);

        eventsArray.forEach(ev => {
          let evId = ev.preferredEvent
          console.log(evId)
          fb.eventsCollection.where("id", "==", evId)
          .get().then(querySnapshot => {
          // .onSnapshot(querySnapshot => {
            querySnapshot.forEach(doc => {
              let myEvent = doc.data()
              // let dateComp = new Date(myEvent.startDate)
              // let yes = new Date(yesterday).getTime()
              // let tod = new Date(myEvent.start).getTime()
              // console.log(tod)
              // console.log(yes)
              // if (tod >= yes) {
              //   myEvents.push(myEvent)
              // }
              myEvents.push(myEvent)
            })
          })
        })
        console.log(myEvents)
        // let uniqueEvents = new Set(myEvents)
        // let myUniqueEvents = [...uniqueEvents]
        // console.log(myUniqueEvents)
        commit('setPreferredEvents', myEvents)
      })
    },
    clearPreferredEvents({ commit }) {
      commit('setPreferredEvents', [])
    },

    clearEventState({ commit }) {
      commit('setEventInfo', {})
      commit('setVenueInfo', {})
      commit('setUserEventStatus', null)
      commit('setActiveAssignments', [])
      commit('setVenueEvents', [])
    },
    clearEventsState({ commit }) {
      commit('setEvents', [])
      commit('setCurrentEvents', [])
      commit('setInfiniteEvents', [])
      commit('setNextInfiniteEvents', [])
      commit('setLatestEvents', [])
      commit('setTaggedEvents', [])
      commit('setVenueEventsSearchResults', [])
    },



    /*SHIFTS*/
    addEventShift({ commit }, payload) {
      fb.shiftsCollection.add(payload)
      .then(
        doc => {
          fb.shiftsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    confirmFromId({ commit }, payload) {
      store.dispatch('getAssignmentFromId', payload)
      fb.assignmentsCollection.doc(payload).update({
        confirmed: true
      })
      fb.assignmentsCollection.doc(payload).get()
      .then(
        doc => {
          store.dispatch('confirmAssignment', doc.data())

        }
      )
    },
    getAssignmentFromId({ commit }, payload) {
      console.log(payload)
      fb.assignmentsCollection.doc(payload).get()
      .then(
        doc => {
          console.log(doc.data())
          store.dispatch('getUserDayFromAssignment', doc.data())
          commit("setAssignmentById", doc.data())
        }
      )
    },
    // getAssignmentFromId({ commit }, payload) {
    //   fb.assignmentsCollection.doc(payload)
    //   .get()
    //   .then(doc => {
    //     console.log(doc.data())
    //     commit("setAssignmentById", doc.data())
    //     store.dispatch('getUserDayFromAssignment', doc.data())
    //   })
    // },
    clearAssignmentId({ commit }) {
      commit('setAssignmentById', {})
      commit("setAssignmentUser", {})
    },
    deleteShift({ commit }, payload) {
      fb.shiftsCollection.doc(payload).delete()
    },
    updateEventShift({ commit }, payload) {
      fb.shiftsCollection.doc(payload.id).update(payload)
    },
    getEventShifts({ commit }, payload) {
      console.log(payload)
      fb.shiftsCollection.where("eventId", "==", payload).orderBy('day', 'desc')
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let shiftsArray = []
        querySnapshot.forEach(doc => {
          let shift = doc.data()
          shift.id = doc.id
          shiftsArray.push(shift)
        })
        commit('setEventShifts', shiftsArray)
      })
    },
    clearEventShifts({ commit }) {
      commit('setEventShifts', [])
    },

      
      
    deleteUserDay({ commit }, payload) {
      let drop =  {
        userId: payload.userId,
        day: payload.day
      }
      console.log(payload)
      fb.userDaysCollection.where("userId", "==", payload.userId).where("day", "==", day).get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          console.log(doc.id, " => ", doc.data())
          fb.userDaysCollection.doc(doc.id).delete()
          console.log("deleted")
        })
      })
      store.dispatch('dropUser', drop)
      store.dispatch("getGroups")
    },

    async dropUser({ commit }, payload) {
      console.log(payload)
      await fb.userDaysCollection.where("userId", "==", payload.userId).where("day", "==", payload.day).get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          console.log(doc.id, " => ", doc.data())
          fb.userDaysCollection.doc(doc.id).delete()
          console.log("deleted")
        })
      })
      // fb.assignmentsCollection.where("userId", "==", payload.userId).where("day", "==", payload.day)
      // .get()
      // .then(function (querySnapshot) {
      //   querySnapshot.forEach(function (doc) {
      //     console.log(doc.id, " => ", doc.data())
      //     fb.assignmentsCollection.doc(doc.id).delete()
      //     console.log("deleted")
      //   })
      // })
      store.dispatch('removeFromPipeline', {
        userId: payload.userId,
        eventId: payload.shift.eventId
      })
    },
    async removeFromPipeline({ commit }, payload) {
      console.log('removeFromPipeline');
      console.log(payload);

      const userId = payload.userId || null;
      const eventId = payload.eventId;

      if (!userId || !eventId) {
        return;
      }

      try {
        const eventUpdate = {
          pipeline: firebase.firestore.FieldValue.arrayRemove(userId),
          pipelineCancelled: firebase.firestore.FieldValue.arrayUnion(userId)
        };

        if (eventId) {
          await fb.usersCollection.doc(userId).update({
            pipeline: firebase.firestore.FieldValue.arrayRemove(eventId),
            cancellationReason: "User Dropped"
          });
        }
        if (userId) {
          await fb.eventsCollection.doc(eventId).update(eventUpdate);
        }
      } catch (error) {
        console.error(error);
      }
    }, 


    /*LEADS*/
    newLead({ commit }, payload) {
      console.log(payload)
      fb.leadsCollection.add(payload)
      .then(
        doc => {
          fb.leadsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },

    /*Support*/
    newSupportRequest({ commit }, payload) {
      console.log(payload)
      fb.supportRequestCollection.add(payload)
      .then(
        doc => {
          fb.supportRequestCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },

    


    /*ASSIGNMENTS*/
    getActiveShiftAssignments({ commit }, payload) {
      console.log(payload)
      fb.assignmentsCollection.where("shiftId", "==", payload)
      // .onSnapshot(querySnapshot => {
        .get().then(querySnapshot => {
        let assignmentsArray = []
        querySnapshot.forEach(doc => {
          // console.log(doc.data())
          // store.dispatch("getEventFromId", doc.data().eventId)
          let shift = doc.data()
          assignmentsArray.push(shift)
        })
        // console.log(assignmentsArray)
        commit('setActiveShiftAssignments', assignmentsArray)
      })
    },
    clearShiftDetails({ commit }, payload) {
      commit('setActiveShiftAssignments', [])
      commit('setEventInfo', {})
      // commit('setAvailableData', [])
    },
    clearActiveShiftAssignments({ commit }, payload) {
      commit('setActiveShiftAssignments', [])
      commit('setEventInfo', {})
      // commit('setAvailableData', [])
    },

    // getActiveDayAssignments({ commit }, payload) {
    //   console.log(payload)
    //   fb.assignmentsCollection.where("day", "==", payload).onSnapshot(querySnapshot => {
    //     let assignmentsArray = []
    //     querySnapshot.forEach(doc => {
    //       console.log(doc.data())
    //       // store.dispatch("getEventFromId", doc.data().eventId)
    //       let shift = doc.data()
    //       assignmentsArray.push(shift)
          
    //     })
    //     console.log(assignmentsArray)
    //     commit('setActiveDayAssignments', assignmentsArray)
    //   })
    // },
    // clearActiveShiftAssignments({ commit }, payload) {
    //   commit('setActiveDayAssignments', [])
    // },

    getActiveAssignment({ commit, state }, payload) {
      console.log(payload)
      if (state.currentUser) {
        fb.assignmentsCollection.where("eventId", "==", payload).where("userId", "==", state.currentUser.uid)
        // .onSnapshot(querySnapshot => {
          .get().then(querySnapshot => {
          let assignmentsArray = []
          querySnapshot.forEach(doc => {
            let shift = doc.data()
            assignmentsArray.push(shift)
          })
          commit('setActiveAssignments', assignmentsArray)
        })
      }
    },
    getUpcomingAssignments({ commit, state }) {
      fb.assignmentsCollection.where("userId", "==", state.currentUser.uid)
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let assignmentsArray = []
        let allAssignmentsArray = []
        querySnapshot.forEach(doc => {
          let shift = doc.data()
          // let yesterday = new Date()
          // yesterday.setDate(yesterday.getDate() - 1.5);
          // let startComp = new Date(doc.data().date)
          // if (startComp > yesterday) {
          //   assignmentsArray.push(shift)
          // }
          allAssignmentsArray.push(shift)
        })
        commit('setMyAssignments', allAssignmentsArray)
      })
    },
    clearUpcomingAssignments({ commit }) {
      commit('setMyAssignments', [])
    },




    getFaqsState({ commit }) {
      fb.faqsCollection.orderBy('order', 'asc')
      .get().then(querySnapshot => {
        // .onSnapshot(querySnapshot => {
        let faqsArray = []
        querySnapshot.forEach(doc => {
          let faq = doc.data()
          faqsArray.push(faq)
        })
        commit('setFaqs', faqsArray)
      })
    },
    clearFaqsState({ commit }) {
      commit('setFaqs', [])
    },







    clearErrors({ commit }) {
      commit('setErrorMessage', '')
    }
  },
  mutations: {
    emailVerified(state, val) {
      state.emailVerified = val
    },
    setCurrentUser(state, val) {
      state.currentUser = val
    },
    setAssignmentUser(state, val){
      state.AssignmentUser = val
    },
    setErrorMessage(state, val) {
      state.errorMessage = val
    },
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setFeaturedVenues(state, val) {
      if (val) {
        state.featuredVenues = val
      } else {
        state.featuredVenues = []
      }
    },
    setVenues(state, val) {
      if (val) {
        state.venues = val
      } else {
        state.venues = []
      }
    },
    setVenueInfo(state, val) {
      state.venueInfo = val
    },
    setVenueEvent(state, val) {
      state.venueEvent = val
    },
    setVenueEvents(state, val) {
      if (val) {
        state.venueEvents = val
      } else {
        state.venueEvents = []
      }
    },
    setVenueJobs(state, val) {
      if (val) {
        state.venueJobs = val
      } else {
        state.venueJobs = []
      }
    },
    setEvents(state, val) {
      if (val) {
        state.events = val
      } else {
        state.events = []
      }
    },
    setLatestEvents(state, val) {
      if (val) {
        state.latestEvents = val
      } else {
        state.latestEvents = []
      }
    },
    setCurrentEvents(state, val) {
      if (val) {
        state.currentEvents = val
      } else {
        state.currentEvents = []
      }
    },
    setTaggedEvents(state, val) {
      if (val) {
        state.taggedEvents = val
      } else {
        state.taggedEvents = []
      }
    },
    setRecentEvents(state, val) {
      if (val) {
        state.recentEvents = val
      } else {
        state.recentEvents = []
      }
    },
    setPreferredEvents(state, val) {
      if (val) {
        state.preferredEvents = val
      } else {
        state.preferredEvents = []
      }
    },
    setPayFAQ(state, val) {
      state.payFAQ = val
    },
    setEventInfo(state, val) {
      state.eventInfo = val
    },
    setAssignmentById(state, val) {
      state.assignment = val
    },
    setAssignmentUser(state, val){
      state.assignmentUser = val
    },
    setUserEventStatus(state, val) {
      state.userEventStatus = val
    },
    setEventShifts(state, val) {
      if (val) {
        state.eventShifts = val
      } else {
        state.eventShifts = []
      }
    },
    setUsers(state, val) {
      if (val) {
        state.users = val
      } else {
        state.users = []
      }
    },
    setUserInfo(state, val) {
      state.userInfo = val
    },
    setWorkerPayHistory(state, val) {
      state.workerPayHistory = val
    },
    setEmployeePayHistory(state, val) {
      state.employeePayHistory = val
    },
    setSkillCategories(state, val) {
      if (val) {
        state.skillCategories = val
      } else {
        state.skillCategories = []
      }
    },
    setJobsByCategory(state, val) {
      if (val) {
        state.jobsByCategory = val
      } else {
        state.jobsByCategory = []
      }
    },
    setJobs(state, val) {
      if (val) {
        state.jobs = val
      } else {
        state.jobs = []
      }
    },
    setJobInfo(state, val) {
      state.jobInfo = val
    },
    setClients(state, val) {
      if (val) {
        state.clients = val
      } else {
        state.clients = []
      }
    },
    setUserTimesheets(state, val) {
      if (val) {
        state.userTimesheets = val
      } else {
        state.userTimesheets = []
      }
    },
    setFollowedVenues(state, val) {
      if (val) {
        state.followedVenues = val
      } else {
        state.followedVenues = []
      }
    },
    setClientInfo(state, val) {
      state.clientInfo = val
    },
    setPerformingRequest(state, val) {
      state.performingRequest = val
    },
    setAvailableData(state, val) {
      if (val) {
        state.userAvailableData = val
      } else {
        state.userAvailableData = []
      }
    },
    setAppliedJobs(state, val) {
      if (val) {
        state.appliedJobs = val
      } else {
        state.appliedJobs = []
      }
    },
    setAvailableDays(state, val) {
      if (val) {
        state.userAvailableDays = val
      } else {
        state.userAvailableDays = []
      }
    },
    setUserReferrals(state, val) {
      if (val) {
        state.userReferrals = val
      } else {
        state.userReferrals = []
      }
    },
    setFaqs(state, val) {
      if (val) {
        state.faqs = val
      } else {
        state.faqs = []
      }
    },
    setGroup(state, val) {
      if (val) {
        state.group = val
      } else {
        state.group = {}
      }
    },
    setGroups(state, val) {
      if (val) {
        state.groups = val
      } else {
        state.groups = []
      }
    },
    setCerts(state, val) {
      if (val) {
        state.certs = val
      } else {
        state.certs = []
      }
    },
    setAvailableShiftsWithoutGroups(state, val) {
      if (val) {
        state.availableShiftsWithoutGroups = val
      } else {
        state.availableShiftsWithoutGroups = []
      }
    },
    setAvailableShifts(state, val) {
      if (val) {
        state.availableShifts = val
      } else {
        state.availableShifts = []
      }
    },
    setAllAvailableShifts(state, val) {
      if (val) {
        state.allAvailableShifts = val
      } else {
        state.allAvailableShifts = []
      }
    },
    setActiveAssignments(state, val) {
      if (val) {
        state.activeAssignments = val
      } else {
        state.activeAssignments = []
      }
    },
    setNYUsers(state, val) {
      if (val) {
        state.nYUsers = val
      } else {
        state.nYUsers = []
      }
    },
    setUpcomingAssignments(state, val) {
      if (val) {
        state.upcomingAssignments = val
      } else {
        state.upcomingAssignments = []
      }
    },
    setMyAssignment(state, val) {
      state.myAssignment = val
    },
    setAssignmentProximity(state, val) {
      state.assignmentProximity = val
    },
    setMyAssignments(state, val) {
      if (val) {
        state.myAssignments = val
      } else {
        state.myAssignments = []
      }
    },
    setUsersTotal(state, val) {
      state.usersTotal = val
    },
    setVenuesTotal(state, val) {
      state.venuesTotal = val
    },
    setCanCheckIn(state, val) {
      state.canCheckIn = val
    },
    setCheckInMaster(state, val) {
      state.checkInMaster = val
    },
    setVerifications(state, val) {
      if (val) {
        state.verifications = val
      } else {
        state.verifications = []
      }
    },
    setInfiniteEvents(state, val) {
      if (val) {
        state.infiniteEvents = val
      } else {
        state.infiniteEvents = []
      }
    },
    setNextInfiniteEvents(state, val) {
      if (val) {
        state.nextInfiniteEvents = val
      } else {
        state.nextInfiniteEvents = []
      }
    },
    setlLastVisibleEventSnapShot(state, val) {
      state.lastVisibleEventSnapShot = val
    },
    setVenueEventsSearchResults(state, val) {
      if (val) {
        state.venueEventsSearchResults = val
      } else {
        state.venueEventsSearchResults = []
      }
    },
    setVenueCheckInEvent(state, val) {
      state.venueCheckInEvent = val
    },
    setDayAssignment(state, val) {
      state.dayAssignment = val
    },
    setTags(state, val) {
      if (val) {
        state.tags = val
      } else {
        state.tags = []
      }
    },
    setEventUsers(state, val) {
      if (val) {
        state.eventUsers = val
      } else {
        state.eventUsers = []
      }
    },
    setGroupApplications(state, val) {
      if (val) {
        state.groupApplications = val
      } else {
        state.groupApplications = []
      }
    },
    setMyShifts(state, val) {
      if (val) {
        state.myShifts = val
      } else {
        state.myShifts = []
      }
    },
    setShiftInfo(state, val) {
      state.shiftInfo = val
    },
    setActiveShiftAssignments(state, val) {
      if (val) {
        state.activeShiftAssignments = val
      } else {
        state.activeShiftAssignments = []
      }
    },
    setGroupShifts(state, val) {
      if (val) {
        state.groupShifts = val
      } else {
        state.groupShifts = []
      }
    },
    setPanels(state, val) {
      if (val) {
        state.panels = val
      } else {
        state.panels = []
      }
    },
    setPipelineHired(state, val) {
      if (val) {
        state.pipelineHired = val
      } else {
        state.pipelineHired = []
      }
    },
    setPipelineCancelled(state, val) {
      if (val) {
        state.pipelineCancelled = val
      } else {
        state.pipelineCancelled = []
      }
    },
    setPipeline(state, val) {
      if (val) {
        state.pipeline = val
      } else {
        state.pipeline = []
      }
    },
    // setAllAvailableShifts(state, val) {
    //   if (val) {
    //     state.allAvailableShifts = val
    //   } else {
    //     state.allAvailableShifts = []
    //   }
    // },
  },
})

export default store
