module.exports = {
  routes: 
  [
    {
      path: '*',
      redirect: '/home'
      // redirect: '/jobs/apply'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('../views/Home.vue')
    },
    {
      path: '/.well-known/acme-challenge/4sI6IRaOyBSOEpYKN9t5VQZJa50PueJKKCj6mNusiDE',
      name: 'acme',
      component: () => import('../views/.well-known/Acme.vue')
    },
    {
      path: '/nytracker',
      name: 'NYTracker',
      component: () => import('../views/NYTracker.vue')
    },
    {
      path: '/tracker/:id/home', redirect: '/tracker/:id',
      name: 'trackerHome',
      component: () => import('../views/Tracker/TrackerHome.vue'),
      props: true,
      // meta: {
      //   requiresAuth: true,
      // },
      children: [
        {
          path: '/tracker/:id', redirect: '/tracker/:id/shifts',
          name: 'Tracker',
          component: () => import('../views/Tracker/Tracker.vue'),
          props: true,
          // meta: {
          //   requiresAuth: true,
          // },
        },
        {
          path: '/tracker/:id/shifts',
          name: 'Shifts',
          component: () => import('../views/Tracker/Shifts.vue'),
          props: true,
          // meta: {
          //   requiresAuth: true,
          // },
        },
      ]
    },
    {
      path: '/form/:id',
      name: 'googleForm',
      component: () => import('../views/Orders/Form.vue')
    },
    {
      path: '/groupform/:id',
      name: 'groupForm',
      component: () => import('../views/Groups/Form.vue')
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   component: () => import('../views/About.vue')
    // },
    {
      path: '/equalopportunity',
      name: 'eeo',
      component: () => import('../views/EEO.vue')
    },
    {
      path: '/safety',
      name: 'safety',
      component: () => import('../views/Safety.vue')
    },
    {
      path: '/hotels',
      name: 'hotels',
      component: () => import('../views/Hotels.vue')
    },
    {
      path: '/janitorial',
      name: 'janitorial',
      component: () => import('../views/Janitorial.vue')
    },
    {
      path: '/live-events',
      name: 'live-events',
      component: () => import('../views/LiveEvents.vue')
    },
    {
      path: '/workers',
      name: 'workers',
      component: () => import('../views/Workers.vue')
    },
    {
      path: '/businesses',
      name: 'businesses',
      component: () => import('../views/Businesses.vue'),
      meta: { sitemap: { ignoreRoute: true }, }
    },
    {
      path: '/quote',
      name: 'quote',
      component: () => import('../views/Quote.vue')
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('../views/Support.vue'),
      meta: { sitemap: { ignoreRoute: true }, }
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('../views/Terms.vue')
    },
    {
      path: '/everify',
      name: 'everify',
      component: () => import('../views/Everify.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('../views/Privacy.vue')
    },
    {
      path: '/guidelines',
      name: 'guidelines',
      component: () => import('../views/Guidelines.vue')
    },
    {
      path: '/contractor',
      name: 'contractor',
      component: () => import('../views/Contractor.vue')
    },
    {
      path: '/lead',
      name: 'leadProgram',
      component: () => import('../views/LeadProgram.vue')
    },
    {
      path: '/auth', redirect: '/auth/login',
      name: 'authHome',
      component: () => import('../views/Auth/AuthHome.vue'),
      meta: { sitemap: { ignoreRoute: true }, },
      children: [
        {
          path: '/auth/login',
          name: 'loginNew',
          component: () => import('../views/Auth/Login.vue'),
          props: true,
        },
        {
          path: '/auth/signup', 
          name: 'loginNew',
          component: () => import('../views/Auth/Login.vue'),
          props: true,
        },
        {
          path: '/auth/verify',
          name: 'verifyPhone',
          component: () => import('../views/Auth/VerifyPhone.vue'),
          props: true,
        },
        {
          path: '/auth/complete',
          name: 'authComplete',
          component: () => import('../views/Auth/AuthComplete.vue'),
          props: true,
        },
        {
          path: '/auth/ny',
          name: 'authNY',
          component: () => import('../views/Auth/AuthNY.vue'),
          props: true,
        },
      ]
    },
    {
      path: '/login', redirect: '/auth/login',
      // name: 'login',
      // component: () => import('../views/Login.vue')
    },
    {
      path: '/signup', redirect: '/auth/signup',
      // name: 'signup',
      // component: () => import('../views/Signup.vue')
    },
    {
      path: '/skills',
      name: 'skills',
      component: () => import('../views/Skills.vue')
    },
    {
      path: '/insurance',
      name: 'insurance',
      component: () => import('../views/Insurance.vue')
    },
    {
      path: '/insurance/oa',
      name: 'oa',
      component: () => import('../views/Account/Insurance/OA.vue')
    },
    {
      path: '/insurance/wc',
      name: 'wc',
      component: () => import('../views/Account/Insurance/WC.vue')
    },
    {
      path: '/uploads',
      name: 'uploads',
      component: () => import('../views/Uploads.vue')
    },
    {
      path: '/hr',
      name: 'HRHome',
      component: () => import('../views/HR/HRHome.vue'),
      meta: {
        requiresAuth: true,
        refreshUser: true
      },
    },
    {
      path: '/account', redirect: '/account/details',
      name: 'accountHome',
      component: () => import('../views/Account/AccountHome.vue'),
      meta: {
        requiresAuth: true,
        sitemap: { ignoreRoute: true },
      },
      children: [
        {
          path: '/account/details',
          name: 'accountDetails',
          component: () => import('../views/Account/AccountDetails.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/account/interview',
          name: 'accountInterview',
          component: () => import('../views/Account/AccountInterview.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/account/skills',
          name: 'accountSkills',
          component: () => import('../views/Account/AccountSkills.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/account/uploads',
          name: 'accountUploads',
          component: () => import('../views/Account/AccountUploads.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/account/id',
          name: 'accountId',
          component: () => import('../views/Account/AccountID.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/account/resume',
          name: 'accountResume',
          component: () => import('../views/Account/AccountResume.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/account/tagline',
          name: 'accountTagline',
          component: () => import('../views/Account/AccountTagline.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/account/emergency',
          name: 'accountEmergency',
          component: () => import('../views/Account/AccountEmergency.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/account/settings',
          name: 'accountSettings',
          component: () => import('../views/Account/AccountSettings.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/account/photos',
          name: 'accountPhotos',
          component: () => import('../views/Account/AccountPhotos.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/account/welcome',
          name: 'accountWelcome',
          component: () => import('../views/Account/AccountWelcome.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/account/harassment',
          name: 'sexualHarassment',
          component: () => import('../views/Account/SexualHarassment.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/account/payroll', redirect: '/account/payroll/home',
          name: 'payrollHome',
          component: () => import('../views/Account/Payroll/PayrollHome.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
          children: [
            {
              path: '/account/payroll/hub',
              name: 'payrollHub',
              component: () => import('../views/Account/Payroll/PayrollHub.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/account/payroll/timesheets',
              name: 'payrollTimesheets',
              component: () => import('../views/Account/Payroll/PayrollTimesheets.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/account/payroll/paystubs',
              name: 'payrollPaystubs',
              component: () => import('../views/Account/Payroll/PayrollPaystubs.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/account/payroll/home',
              name: 'payrollMenu',
              component: () => import('../views/Account/Payroll/PayrollMenu.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/account/payroll/onboarding',
              name: 'payrollOnboarding',
              component: () => import('../views/Account/Payroll/PayrollOnboarding.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
          ]
        },
        {
          path: '/account/employee', redirect: '/account/employee/home',
          name: 'employeeHome',
          component: () => import('../views/Account/Employee/EmployeeHome.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
          children: [
             {
              path: '/account/employee/home',
              name: 'employeeMenu',
              component: () => import('../views/Account/Employee/EmployeeMenu.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/account/employee/onboarding',
              name: 'employeeOnboarding',
              component: () => import('../views/Account/Employee/EmployeeOnboarding.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/account/employee/paystubs',
              name: 'employeePaystubs',
              component: () => import('../views/Account/Employee/EmployeePaystubs.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
          ]
        },
      ]
    },
    {
      path: '/jobs', redirect: '/jobs/apply',
      name: 'newGroupsHome',
      component: () => import('../views/Groups/FindGroups.vue'),
      children: [
        {
          path: '/jobs/myjobs',
          name: 'myGroups',
          component: () => import('../views/Groups/MyGroups.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/jobs/myjobs/:id',
          name: 'groupShifts',
          component: () => import('../views/Groups/GroupShifts.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/jobs/apply',
          name: 'findGroups',
          component: () => import('../views/Groups/NewGroups.vue'),
          props: true,
        },
        {
          path: '/jobs/apply/:id',
          name: 'group',
          component: () => import('../views/Groups/Group.vue'),
          //  meta: {
          //   requiresAuth: true,
          //   sitemap: { ignoreRoute: true },
          // },
        },
        {
          path: '/jobs/apply/:id/application',
          name: 'groupApplication',
          component: () => import('../views/Groups/Application.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
      ]
    },
    {
      path: '/work', redirect: '/work/availableshifts',
      name: 'findWork',
      component: () => import('../views/Work/FindWork.vue'),
      // meta: {
      //   requiresAuth: true,
      //   sitemap: { ignoreRoute: true },
      // },
      children: [
        {
          path: '/work/availableshifts',
          name: 'availableShifts',
          component: () => import('../views/Work/AvailableShifts.vue'),
          props: true,
        },
        {
          path: '/work/myshifts',
          name: 'myShifts',
          component: () => import('../views/Work/MyShifts.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/work/myshifts/:id',
          name: 'myShift',
          component: () => import('../views/Work/Shift.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            sitemap: { ignoreRoute: true },
          },
        },
        {
          path: '/work/availableshifts/:id',
          name: 'shiftView',
          component: () => import('../views/Work/ShiftView.vue'),
          props: true,
          meta: {
            sitemap: { ignoreRoute: true },
          },
        },
      ]
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('../views/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        sitemap: { ignoreRoute: true },
      },
    },
    
    {
      path: '/confirm/:id',
      name: 'confirm',
      component: () => import('../views/Confirm.vue'),
      meta: { sitemap: { ignoreRoute: true }, }
    },
    {
      path: '/cancel/:id',
      name: 'cancel',
      component: () => import('../views/Cancel.vue'),
      meta: { sitemap: { ignoreRoute: true }, }
    },
    {
      path: '/profile', redirect: '/account/details',
      meta: { sitemap: { ignoreRoute: true }, }
    },
    {
      path: '/checkinout/:id',
      name: 'checkInOut',
      component: () => import('../views/CheckInOut.vue'),
      meta: {
        sitemap: { ignoreRoute: true },
      },
    },
    {
      path: '/checkin',
      name: 'checkInGeo',
      component: () => import('../views/CheckInGeo.vue'),
      meta: {
        sitemap: { ignoreRoute: true },
      },
    },
  ]
}