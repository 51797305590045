import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../firebaseConfig'
const { routes } = require('./routes.js');
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  let currentUser = auth.currentUser;
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/auth/login')
  } 
  else if (!requiresAuth && currentUser) next();
  else 
  {
    next()
  }
})

export default router
