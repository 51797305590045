<template>
  <header>
    <div class="nav" :class="{ offset: showBar }">
      <div class="nav__container">
        <div class="nav__logo ml-3">
          <router-link to="/home">
            <span>J<em>u</em>mp</span>
            
          </router-link>
        </div>
        <div class="nav__menu" v-if="!userProfile || !userProfile.id">

          <div class="nav__menu--item desktop">
              <router-link :to="{name: 'findGroups'}">Careers</router-link>
            </div>
            <div class="nav__menu--item desktop">
              <router-link :to="{name: 'availableShifts'}">Gig Work</router-link>
            </div>

            <div class="nav__menu--item desktop">
            <button @click="login()" class="btn btn__primary">Login / Sign Up</button>
          </div>

          <!-- <div class="nav__menu--item desktop">
            <router-link :to="{name: 'findGroups'}"><i class="fa-regular fa-magnifying-glass mr-2"></i>Search Careers</router-link>
          </div> -->

          

          <!-- <div class="nav__menu--item desktop">
            <button @click="login()" class="btn btn__primary">Login</button>
          </div> -->

          <!-- <div class="nav__menu--item desktop">
            <button @click="signup()" class="btn btn__primary">Sign Up to Work</button>
          </div> -->

          <!-- <div class="nav__menu--item mobile mr-3">
            <router-link :to="{name: 'findGroups'}"><i class="fa-regular fa-magnifying-glass mr-2"></i>Careers</router-link>
          </div> -->

          <div class="nav__menu--item mobile">
            <button @click="login()" class="btn btn__primary">Login / Signup</button>
          </div>



          <div class="nav__menu--item ml-3 mr-2">
            <a @click="showSideNav()" class="no_animation pb-0">
              <i class="fal fa-bars fa-2x" v-show="!showSide"></i>
              <i class="fal fa-times fa-2x" v-show="showSide"></i>
            </a>
          </div>
        </div>

        <div class="nav__menu" v-if="userProfile && userProfile.id && !userProfile.center">
          <div class="nav__menu--item desktop">
            <router-link :to="{name: 'authComplete'}">Complete Account Setup</router-link>
          </div>
          <div class="nav__menu--item ml-3 mr-2">
            <a @click="showSideNav()" class="no_animation pb-0">
              <i class="fal fa-bars fa-2x" v-show="!showSide"></i>
              <i class="fal fa-times fa-2x" v-show="showSide"></i>
            </a>
          </div>
        </div>

        <div class="nav__menu" v-if="userProfile && userProfile.id && userProfile.center">
          <div class="nav__menu--item desktop">
            <router-link :to="{name: 'findGroups'}">Search Careers</router-link>
          </div>
          <span class="hidden-small">|</span>
          <div class="nav__menu--item desktop">
            <router-link :to="{name: 'availableShifts'}">Gig Work</router-link>
          </div>
          <span class="hidden-small">|</span>
          <div class="nav__menu--item desktop">
            <router-link :to="{name: 'myShifts'}">My Schedule</router-link>
          </div>
          <!-- <div class="nav__menu--item mobile mr-3">
            <router-link :to="{name: 'findGroups'}">Careers</router-link>
          </div> -->
          <div class="nav__menu--item desktop" v-if="currentUser && pic">
            <img :src="pic" alt="" class="avatar">
          </div>
          <div class="nav__menu--item ml-3 mr-2">
            <a @click="showSideNav()" class="no_animation pb-0">
              <i class="fal fa-bars fa-2x" v-show="!showSide"></i>
              <i class="fal fa-times fa-2x" v-show="showSide"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <div class="sideNav" v-if="showSide" v-click-outside="onClickOutside">
          <div class="nav__menu--item justify-space-between" style="position: absolute; right:3.2rem; top:0.8rem; z-index: 500; width: calc(100% - 5.2rem); border-bottom:none;">
            <span>
              <img :src="pic" alt="" class="avatar" v-if="userProfile && userProfile.id">
            </span>
     

            <a @click="showSideNav()">
              <i class="fal fa-times fa-2x" v-show="showSide" style="font-size:3rem;"></i>
            </a>
          </div>
          <div class="mt-5 mb-5 pt-5">

          </div>

          <span v-if="!userProfile || !userProfile.id">

            <div class="nav__menu--item">
              <router-link :to="{name: 'findGroups'}">Careers</router-link>
            </div>
            <div class="nav__menu--item">
              <router-link :to="{name: 'availableShifts'}">Gig Work</router-link>
            </div>

            <div class="nav__menu--item">
              <router-link :to="{name: 'signupNew'}">Create an Account</router-link>
            </div>

            
           <!--  <div class="nav__menu--item">
              <router-link :to="{name: 'signupNew'}">Sign Up</router-link>
            </div> -->

           <!--  <div class="nav__menu--item">
              <router-link :to="{name: 'home'}">Home</router-link>
            </div> -->
            <div class="nav__menu--item">
              <router-link :to="{name: 'quote'}">Get Pricing</router-link>
            </div>

            <!-- <div class="nav__menu--item">
              <router-link :to="{name: 'about'}">About Us</router-link>
            </div> -->

            
            
            <div class="nav__menu--item">
              <router-link :to="{name: 'live-events'}">Live Events</router-link>
            </div>
            <div class="nav__menu--item">
              <router-link :to="{name: 'hotels'}">Hotels</router-link>
            </div>
            <div class="nav__menu--item">
              <router-link :to="{name: 'janitorial'}">Facilities Services</router-link>
            </div>
          </span>

          <div class="nav__menu" v-if="userProfile && userProfile.id && !userProfile.center">
            <div class="nav__menu--item">
              <router-link :to="{name: 'authComplete'}">Complete Account Setup</router-link>
            </div>
          </div>

         
          <span v-if="userProfile && userProfile.id && userProfile.center">
            <div class="nav__menu--item" style="padding-top:2rem;">
              <router-link :to="{name: 'findGroups'}"><i class="fa-regular fa-magnifying-glass mr-3"></i>Search Careers</router-link>
            </div>
            <div class="nav__menu--item">
              <router-link :to="{name: 'availableShifts'}"><i class="fa-regular fa-badge-dollar mr-3"></i>Find Gig Work</router-link>
            </div>
             <div class="nav__menu--item">
              <router-link :to="{name: 'myShifts'}"><i class="fa-sharp fa-regular fa-calendar-clock mr-3"></i>My Schedule</router-link>
            </div>
            <!-- <div class="nav__menu--item">
              <router-link :to="{name: 'myGroups'}"><i class="fa-regular fa-bullseye-pointer mr-3"></i>My Active Jobs</router-link>
            </div> -->

            <div class="nav__menu--item">
              <router-link :to="{name: 'accountDetails'}"><i class="fa-regular fa-gear mr-3"></i>Account Settings</router-link>
            </div>

            <div class="nav__menu--item">
              <router-link :to="{name: 'accountSkills'}"><i class="fa-regular fa-check-double mr-3"></i>My Job Skills</router-link>
            </div>
            
            <!-- <div class="nav__menu--item">
              <router-link :to="{name: 'accountInterview'}"><i class="fa-regular fa-list-check mr-3"></i>Interview Questions</router-link>
            </div>
            <div class="nav__menu--item">
              <router-link :to="{name: 'leadProgram'}"><i class="fa-regular fa-trophy mr-3"></i>Become a Shift Lead</router-link>
            </div> -->
   <!--          <div class="nav__menu--item">
              <router-link :to="{name: 'uploads'}"><i class="fa-regular fa-upload mr-3"></i>My Uploads</router-link>
            </div> -->
            <div class="nav__menu--item">
              <router-link :to="{name: 'insurance'}"><i class="fa-regular fa-note-medical mr-3"></i>My Insurance</router-link>
            </div>

            <!-- <div class="nav__menu--item">
              <router-link :to="{name: 'accountUploads'}"><i class="fa-solid fa-file-certificate mr-3"></i>Certifications</router-link>
            </div>
            <div class="nav__menu--item">
              <router-link :to="{name: 'accountResume'}"><i class="fa-solid fa-file-user mr-3"></i>Upload Your Resumé</router-link>
            </div>
            <div class="nav__menu--item">
              <router-link :to="{name: 'accountId'}"><i class="fa-solid fa-id-card mr-3"></i>Driver's License / ID</router-link>
            </div>
            <div class="nav__menu--item">
              <router-link :to="{name: 'accountPhotos'}"><i class="fa-regular fa-image mr-3"></i>Profile Photos</router-link>
            </div> -->
            
            <div class="nav__menu--item" v-if="userProfile && (userProfile.workerId || userProfile.employeeId)">
              <router-link :to="{name: 'payrollHub'}"><i class="fa-regular fa-money-check-dollar-pen mr-3"></i>My Payroll</router-link>
            </div>
            <div class="nav__menu--item">
              <router-link :to="{name: 'HRHome'}"><i class="fa-solid fa-handshake-angle mr-3"></i>Human Resources</router-link>
            </div>


   
<!--             <div class="nav__menu--item" v-if="userProfile && userProfile.employmentType == 'EMPLOYEE'">
              <router-link :to="{name: 'employeeMenu'}"><i class="fa-regular fa-money-check-dollar-pen mr-3"></i>Employee Payroll Settings</router-link>
            </div> -->
            
            <div class="nav__menu--item" v-if="currentUser && currentUser.uid">
              <a @click="logout()"><i class="fa-regular fa-arrow-right-from-bracket mr-3"></i>Log Out</a>
            </div>
            <div class="nav__menu--item" style="padding-bottom:30vh;">
              <router-link :to="{name: 'support'}"><i class="fa-sharp fa-regular fa-envelope mr-3"></i>Get Support</router-link>
            </div>
          </span>
          

     <!--      <div class="nav__menu--item" v-if="userProfile && userProfile.id">
            <a @click="toggleAccount()">
              My Account <i v-if="!accountMenu" class="ml-2 fa-sharp fa-solid fa-chevron-down"></i><i v-if="accountMenu" class="ml-2 fa-sharp fa-solid fa-chevron-up"></i>
              <transition name="fadeStop">
                <div class="pt-3" v-if="accountMenu">
                  <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                    <router-link :to="{name: 'accountDetails'}" class="pb-0">Account Settings</router-link>
                  </div>
                  <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                    <router-link :to="{name: 'accountTagline'}" class="pb-0">Tagline</router-link>
                  </div>
                  <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                    <router-link :to="{name: 'accountSkills'}" class="pb-0">Skills</router-link>
                  </div>
                  <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                    <router-link :to="{name: 'accountUploads'}" class="pb-0">Upload Docs</router-link>
                  </div>
                  <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                    <router-link :to="{name: 'accountPhotos'}" class="pb-0">My Photos</router-link>
                  </div>
                  <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                    <router-link :to="{name: 'accountEmergency'}" class="pb-0">Emergency Contacts</router-link>
                  </div>
                  <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                    <router-link :to="{name: 'payrollMenu'}" class="pb-0">Payroll Settings</router-link>
                  </div>
                  <div class="nav__menu--item pa-2 ml-3" style="border:none;" v-if="userProfile && userProfile.employmentType == 'EMPLOYEE'">
                    <router-link :to="{name: 'employeeMenu'}" class="pb-0">Employee Settings</router-link>
                  </div>
                  <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                    <router-link :to="{name: 'accountSettings'}" class="pb-0">Account Settings</router-link>
                  </div>
                </div>
              </transition>
            </a>
          </div>
 -->
          <!-- <div class="nav__menu--item" v-if="userProfile && userProfile.id">
            <a>
              My Account 
              
              <div class="pt-3">
                <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                  <router-link :to="{name: 'accountDetails'}" class="pb-0">Contact Details</router-link>
                </div>
                <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                  <router-link :to="{name: 'accountTagline'}" class="pb-0">Tagline</router-link>
                </div>
                <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                  <router-link :to="{name: 'accountSkills'}" class="pb-0">Skills</router-link>
                </div>
                <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                  <router-link :to="{name: 'accountUploads'}" class="pb-0">Upload Docs</router-link>
                </div>
                <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                  <router-link :to="{name: 'accountPhotos'}" class="pb-0">My Photos</router-link>
                </div>
                <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                  <router-link :to="{name: 'accountEmergency'}" class="pb-0">Emergency Contacts</router-link>
                </div>
                <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                  <router-link :to="{name: 'payrollMenu'}" class="pb-0">Payroll Settings</router-link>
                </div>
                <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                  <router-link :to="{name: 'accountSettings'}" class="pb-0">Account Settings</router-link>
                </div>
              </div>
              
            </a>
          </div> -->

          <!-- <div class="nav__menu--item" v-if="userProfile && userProfile.id">
            <a>
              Find Work
              
              <div class="pt-3">
                <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                  <router-link :to="{name: 'availableShifts'}" class="pb-0">Pick Up Shifts</router-link>
                </div>
                <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                  <router-link :to="{name: 'myShifts'}" class="pb-0">My Shifts</router-link>
                </div>
              </div>
            </a>
          </div> -->

         <!--  <div class="nav__menu--item" v-if="userProfile && userProfile.id">
            <router-link :to="{name: 'findWork'}">Find Work</router-link>
          </div> -->

     <!--      <div class="nav__menu--item" v-if="userProfile && userProfile.id">
            <a>
              Join Groups
              
              <div class="pt-3">
                <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                  <router-link :to="{name: 'findGroups'}" class="pb-0">Join Groups</router-link>
                </div>
                <div class="nav__menu--item pa-2 ml-3" style="border:none;">
                  <router-link :to="{name: 'myGroups'}" class="pb-0">My Groups</router-link>
                </div>
              </div>
            </a>
          </div>
 -->
          <!-- <div class="nav__menu--item" v-if="userProfile && userProfile.id">
            <router-link :to="{name: 'newGroupsHome'}">Join Groups</router-link>
          </div> -->

          

          <!-- <div class="nav__menu--item" v-if="userProfile && userProfile.id">
            <router-link :to="{name: 'events'}">Events</router-link>
          </div> -->


       <!--    <div class="nav__menu--item" v-if="userProfile && userProfile.id">
            <router-link :to="{name: 'events'}">Find Work</router-link>
          </div> -->

         <!--  <div class="nav__menu--item" v-if="userProfile && userProfile.id">
            <router-link :to="{name: 'venues'}">Venues</router-link>
          </div> -->

          


          

         <!--  <div class="nav__menu--item" v-if="userProfile && userProfile.id">
            <a href="https://brnch.app.link/JumpStaffing" target="_blank">
              Get Paid
            </a>
          </div> -->

          <!-- <div class="nav__menu--item"  v-if="userProfile && userProfile.id">
            <a @click="logout()">Logout</a>
          </div> -->

          <!-- <div class="nav__menu--item" v-if="!userProfile || !userProfile.id">
            <router-link :to="{name: 'login'}">Signup / Login</router-link>
          </div> -->

          
          

      <!--      <div class="nav__menu--item">
        <a  href="https://apps.apple.com/us/app/mvp-event-staffing/id1591881518">
          <img src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/appimg.png?alt=media&token=c932fffe-48f2-43e6-8b12-f984854dcafd" alt="" style="width:120px;">
        </a>
      </div>
       <div class="nav__menu--item">
        <a  href="https://play.google.com/store/apps/details?id=com.kptech80.mvpeventstaffing">
          <img src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/andimg.png?alt=media&token=4506d39d-bcd6-4d3f-b091-62699aad79a3" alt="" style="width:120px;">
        </a>
      </div> -->

          
        </div>
    </transition>
  </header>
</template>

<style type="text/css" scoped>
  .offset {
    top: 3.6rem;
  }
</style>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import Vue from 'vue'


export default {
  props: ['userProfile'],
  data: () => ({
    showSide: false,
    accountMenu: true,
    industryMenu: false,
  }),
  computed: {
    ...mapState(['currentUser']),
    // payroll24() {
    //   if (this.userProfile.payrollSubmitted)
    // },
    showBar() {
      if ((!this.userProfile || !this.userProfile.id) && (this.$route.name == 'home' || this.$route.name == 'findGroups')) {
        return true
      } else {
        return false
      }
    },
    pic () {
      if (this.userProfile) {
        return this.userProfile.photoUrl || 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/avatar%20copy.png?alt=media&token=966c07c4-125a-490f-81be-4e2d26bf33fa'
      }
    },
    isHome() {
      if (this.$route.name == 'home') {
        return true
      } else {
        return false
      }
    }
  },
  watch:{
    $route (to, from){
      this.showSide = false;
    }
  },
  // watch:{
  //   $route (to, from) { 
  //     this.nextTick(() => {
  //       this.showSide = false;
  //       this.aboutMenu = false;
  //       this.workMenu = false;
  //       this.accountMenu = false;
  //       this.helpMenu = false;
  //     })
  //   }
  // },
  methods: {
    toggleIndustry() {
      this.industryMenu = !this.industryMenu
      // this.showSide = true
    },
    toggleAccount() {
      this.accountMenu = !this.accountMenu
    },

    onClickOutside (event) {
      this.showSide = false
    },
    login() {
      let url = `/auth/login`
      router.push(url)
    },
    signup(){
      let url = `/auth/signup`
      router.push(url)
    },
    logout() {
      this.$store.dispatch('logout')
      this.showSide = false
    },
    showSideNav() {
      this.showSide = !this.showSide
    }
  },
  destroyed() {
    this.showSide = false
    this.accountMenu = true
    this.industryMenu = true
  }
}
</script>