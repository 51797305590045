import firebase from 'firebase/app';

require("firebase/auth")
require("firebase/storage")
require("firebase/firestore")
require("firebase/functions")
require("firebase/analytics")
require("firebase/performance")

const firebaseConfig = {
  // apiKey: "AIzaSyA2Zh7agJPBfwU_VX4rHYazUqrFBWEfuuU",
  // authDomain: "mvpes-25aef.firebaseapp.com",
  // projectId: "mvpes-25aef",
  // storageBucket: "mvpes-25aef.appspot.com",
  // messagingSenderId: "79524683437",
  // appId: "1:79524683437:web:e7f9512195f1e011aa3024",
  // measurementId: "G-1JQ4GHY4GZ"

  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_KEY_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
}
firebase.initializeApp(firebaseConfig);
firebase.analytics()
firebase.performance()
firebase.firestore().enablePersistence()

const db = firebase.firestore()
const firestore = firebase.firestore
const auth = firebase.auth()
const storageRef = firebase.storage().ref()
const currentUser = auth.currentUser
const functions = firebase.functions()


const usersCollection = db.collection('users')
const users2Collection = db.collection('users2')
const jobsCollection = db.collection('jobs')
const questionsCollection = db.collection('questions')
const mailCollection = db.collection('mail')
const eventsCollection = db.collection('events')
const venuesCollection = db.collection('venues')
const groupsCollection = db.collection('groups')
const applicationsCollection = db.collection('applications')
const venueFollowersCollection = db.collection('venueFollowers')
const userDaysCollection = db.collection('userDays')
const noticesCollection = db.collection('notices')
const assignmentsCollection = db.collection('assignments')
const leadsCollection = db.collection('leads')
const shiftsCollection = db.collection('shifts')
const faqsCollection = db.collection('faqs')
const oprCollection = db.collection('opr')
const coprCollection = db.collection('copr')
const payHelpCollection = db.collection('payHelp')
const dropsCollection = db.collection('drops')
const referralsCollection = db.collection('referrals')
const totalsCollection = db.collection('totals')
const businessUsersCollection = db.collection('businessUsers')
const clientAccessCollection = db.collection('clientAccess')
const checkInMasterCollection = db.collection('checkInMaster')
const verificationsCollection = db.collection('verifications')
const tagsCollection = db.collection('tags')
const payrollSubmissionsCollection = db.collection('payrollSubmissions')
const leadApplicationsCollection = db.collection('leadApplications')
const supportRequestCollection = db.collection('supportRequest')
const quoteRequestsCollection = db.collection('quoteRequests')
const interviewQueueCollection = db.collection('interviewQueue')
const skillsCollection = db.collection('skills')
const skillCategoriesCollection = db.collection('skillCategories')
const certsCollection = db.collection('certs')
const marketingLeadsCollection = db.collection('marketingLeads')
const panelsCollection = db.collection('panels')
const statsCollection = db.collection('stats')
const loginsCollection = db.collection('logins')
const metaCollection = db.collection('meta')





export {
  db,
  auth,
  storageRef,
  firestore,
  currentUser,
  functions,
  usersCollection,
  users2Collection,
  jobsCollection,
  questionsCollection,
  mailCollection,
  eventsCollection,
  venuesCollection,
  applicationsCollection,
  venueFollowersCollection,
  userDaysCollection,
  noticesCollection,
  assignmentsCollection,
  leadsCollection,
  shiftsCollection,
  faqsCollection,
  oprCollection,
  coprCollection,
  payHelpCollection,
  dropsCollection,
  referralsCollection,
  totalsCollection,
  clientAccessCollection,
  checkInMasterCollection,
  verificationsCollection,
  tagsCollection,
  leadApplicationsCollection,
  payrollSubmissionsCollection,
  businessUsersCollection,
  supportRequestCollection,
  groupsCollection,
  quoteRequestsCollection,
  interviewQueueCollection,
  skillsCollection,
  skillCategoriesCollection,
  certsCollection,
  marketingLeadsCollection,
  panelsCollection,
  statsCollection,
  loginsCollection,
  metaCollection
}