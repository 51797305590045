import Vue from 'vue'
require('dotenv').config()
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebaseConfig'
import Croppa from 'vue-croppa'
import AOS from "aos"
import "aos/dist/aos.css"
import VTooltip from 'v-tooltip'
import VueScrollTo from 'vue-scrollto'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import vClickOutside from 'v-click-outside'
import VueMeta from 'vue-meta'
import VueGoodTablePlugin from 'vue-good-table'
import * as VueGoogleMaps from "vue2-google-maps"
import {GmapMarker} from 'vue2-google-maps/src/components/marker'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import VueMask from 'v-mask'
import VueAgile from 'vue-agile'
import 'vue-good-table/dist/vue-good-table.css'
import VueEllipseProgress from 'vue-ellipse-progress';
// import VueCryptojs from 'vue-cryptojs'
import VueLazyload from 'vue-lazyload'
import OtpInput from "@bachdgvn/vue-otp-input";
import moment from 'moment'
import VueJsonLD from 'vue-jsonld'

Vue.filter('moment', function (value, format) {
  if (value === null || value === undefined || value == '' || format === undefined) {
      return ''
  }
  if (format === 'from') {
      return moment(value).fromNow()
  }
  return moment(value).format(format)
})

import './assets/sass/main.scss'
const fb = require('./firebaseConfig.js')
const cors = require('cors')
const axios = require('axios');

// app.use(cors())

// Vue.use(require('prerender-node').set('prerenderToken', 'tHmPRuOP3O5pmcg8IKfI').whitelisted('^/'));
Vue.use(VueJsonLD)
Vue.component('v-select', vSelect)
Vue.component("v-otp-input", OtpInput);
Vue.use(VueGoodTablePlugin)
Vue.use(VueEllipseProgress)
Vue.use(Croppa)
Vue.use(VueAgile)
Vue.use(VueMask)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/blank2.jpg?alt=media&token=132b4e7a-a455-419d-97e1-3f5ca32b9138',
  error: 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/blank2.jpg?alt=media&token=132b4e7a-a455-419d-97e1-3f5ca32b9138'
})
Vue.use(VTooltip)
Vue.use(vClickOutside)
// Vue.use(VueCryptojs)
Vue.use(require('vue-moment'))

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  refreshOnceOnNavigation: true
})
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA2Zh7agJPBfwU_VX4rHYazUqrFBWEfuuU',
    // key: 'AIzaSyBvT8-4gdOJ8CRHv2mD_MAohqD8vo0To10',
    libraries: 'places' // necessary for places input
  }
})

Vue.use(VueScrollTo, {
   duration: 750,
   easing: "ease-in",
   offset: 0,
 })

Vue.config.productionTip = false
Vue.config.devtools = true


Vue.filter('uppercase', function (value) {
  return value.toUpperCase()
})

Vue.filter('lowercase', function (value) {
  return value.toLowerCase()
})

Vue.filter('truncate', function (text, length, suffix) {
    return text.substring(0, length) + suffix;
})

Vue.filter('readMore', function (text, length, suffix) {
  return text.substring(0, length) + suffix;
})

let app
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      created() {
        AOS.init({ 
          easing: 'ease-in-out',
          offset: 0,
          duration: 300,
          once: true,
          delay: 0,
        });
      },
      render: h => h(App)
    }).$mount('#app')
  }

  // if (user) {
  //   store.dispatch('fetchUserProfile', user)
  // }
})