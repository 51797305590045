<template>
  <div id="app">
    <UtilityBar v-if="showBar" />
    <Nav :userProfile="userProfile || null" v-if="!isForm && !isTracker && !isNYTracker" />
    <!-- <router-view :key="$route.fullPath"></router-view>  -->
    <!-- <keep-alive  max="3" :include="['availableShifts', 'findGroups']" :exclude="['myShift']">
      <router-view :key="$route.fullPath"></router-view> 
    </keep-alive> -->
    <router-view :key="$route.fullPath"></router-view> 
    <!-- <keep-alive  max="2" :exclude="['myShift', 'group']">
      
    </keep-alive> -->
    <transition name="fade">
      <div v-if="errorMessage" class="errorMessage" >
      {{errorMessage}}
      <button @click="close" class="btn btn__flat"><i class="far fa-times-circle fa-2x"></i></button>
      </div>
    </transition>
    <!-- <transition name="fade">
      <router-link :to="{name: 'checkInGeo'}" v-if="showCheckInButton">
        <button class="btn btn__checkinMaster">
          Clock In - Out 
        </button>
      </router-link>
    </transition> -->

    <Footer v-if="!isForm && !isTracker && !isNYTracker" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
import router from '@/router'
import UtilityBar from '@/components/UtilityBar'

export default {
  components: {
    Nav,
    Footer,
    UtilityBar
  },
  data: () => ({
    performingRequest: false,
    lat: 0,
    lng: 0,
  }),
  watch:{
    $route (to, from){
      this.$store.dispatch('clearErrors')
    },
    // lat: 'checkLatLng',
    // lng: 'checkLatLng'
  },
  methods: {
    // checkLatLng() {
    //   if (this.lat && this.lng && this.lat !== 0) {
    //     this.getAss();
    //   }
    // },
    close() {
      this.$store.dispatch('clearErrors')
    },
    // locatorButtonPressed() {
    //   navigator.geolocation.getCurrentPosition(
    //      position => {
    //         this.lat = (position.coords.latitude || 39.1031182);
    //         this.lng = (position.coords.longitude || -84.5120196);
    //       },
    //      error => {
    //        console.log(error.message);
    //      },
    //   )
    // },
    // getAss() {
    //   let coords = {
    //     lat: this.lat,
    //     lng: this.lng
    //   }
    //   this.$store.dispatch("getAssignmentProximity", coords)
    // }
  },
  // created() {
  //   this.locatorButtonPressed()
  // },
  // mounted () {
  //   this.$store.dispatch("getMyAssignment")
  //   this.checkLatLng();
  // },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'errorMessage', 'myAssignment', 'assignmentProximity']),
    showBar() {
      if ((!this.userProfile || !this.userProfile.id) && (this.$route.name == 'home' || this.$route.name == 'findGroups')) {
        return true
      } else {
        return false
      }
    },
    // showCheckInButton() {
    //   return (this.$route.name != 'checkInGeo' && this.assignmentProximity && this.assignmentProximity > 1)
    // },
    isForm() {
      return (this.$route.name == 'googleForm' || this.$route.name == 'groupForm')
    },
    isTracker() {
      return (this.$route.name == 'Tracker' || this.$route.name == 'Shifts')
    },
    isNYTracker() {
      return (this.$route.name == 'Tracker' || this.$route.name == 'NYTracker')
    },
    showNav() {
      return Object.keys(this.userProfile).length > 1
    },
    isHome() {
      if (this.$route.name == 'home') {
        return true
      } else {
        return false
      }
    }
  },
  metaInfo: () => ({
    title: 'Jump Staffing',
    titleTemplate: '%s | Jumpstart Your Career',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: 'On-demand workers for facilities, live sports and entertainment, hotels, conventions, warehouses, and more.',
        image: 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/logos%2FJump%20Logo%20Official%20(2).png?alt=media&token=b05c3dc2-22d7-4f2e-8085-2e1cfbdb47b9'
      }
    ]
    // meta: [{
    //   vmid: 'description',
    //   name: 'description',
    //   content: 'On-demand staffing for facilities, live sports and entertainment, hotels, conventions, warehouses, and more',
    // }],
  })
}
</script>